import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


export type AddParticipantsItem = {
  companyId: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  group?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  namePronunciation?: Maybe<Scalars['String']>;
  notificationPreference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferredName: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sessionId: Scalars['String'];
};

export type AddParticipantsOutput = {
  __typename?: 'AddParticipantsOutput';
  failedAddingParticipants: Array<Scalars['String']>;
  failedAddingParticipantsCount: Scalars['Int'];
  successfullyAddedParticipants: Scalars['Int'];
  successfullyUpdatedParticipants: Scalars['Int'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type ChangePreferencesOutput = {
  __typename?: 'ChangePreferencesOutput';
  id: Scalars['String'];
};

export type FinishSessionOutput = {
  __typename?: 'FinishSessionOutput';
  resourceId?: Maybe<Scalars['String']>;
  sessionId: Scalars['String'];
  sid?: Maybe<Scalars['String']>;
};

export type GetAgoraTempTokenOutput = {
  __typename?: 'GetAgoraTempTokenOutput';
  channelName: Scalars['String'];
  participantAgoraId: Scalars['Int'];
  token: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type RegisterEventOutput = {
  __typename?: 'RegisterEventOutput';
  created_at: Scalars['String'];
  id: Scalars['String'];
};

export type StartRecordingOutput = {
  __typename?: 'StartRecordingOutput';
  resourceId: Scalars['String'];
  sessionId: Scalars['String'];
  sid: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type SyntethizeTextToSpeechOutput = {
  __typename?: 'SyntethizeTextToSpeechOutput';
  audioContent: Scalars['String'];
};

export type TalkTimeMessageEventOutput = {
  __typename?: 'TalkTimeMessageEventOutput';
  id: Scalars['String'];
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  address_1: Scalars['String'];
  address_2: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip_code: Scalars['String'];
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
  address_1?: Maybe<String_Comparison_Exp>;
  address_2?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint */
  AddressPkey = 'address_pkey'
}

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** on conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  address_1?: Maybe<Order_By>;
  address_2?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  Address_1 = 'address_1',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  Address_1 = 'address_1',
  /** column name */
  Address_2 = 'address_2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  /** An object relationship */
  address: Address;
  address_id: Scalars['uuid'];
  /** An object relationship */
  company_contact: Company_Contact;
  company_contact_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  participants: Array<Participant>;
  /** An aggregate relationship */
  participants_aggregate: Participant_Aggregate;
  /** An array relationship */
  sessions: Array<Session>;
  /** An aggregate relationship */
  sessions_aggregate: Session_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_logins: Array<User_Login>;
  /** An aggregate relationship */
  user_logins_aggregate: User_Login_Aggregate;
};


/** columns and relationships of "company" */
export type CompanyParticipantsArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyParticipants_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySessionsArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyUser_LoginsArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyUser_Logins_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: Maybe<Array<Company_Bool_Exp>>;
  _not?: Maybe<Company_Bool_Exp>;
  _or?: Maybe<Array<Company_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Uuid_Comparison_Exp>;
  company_contact?: Maybe<Company_Contact_Bool_Exp>;
  company_contact_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  participants?: Maybe<Participant_Bool_Exp>;
  sessions?: Maybe<Session_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_logins?: Maybe<User_Login_Bool_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint */
  CompanyPkey = 'company_pkey'
}

/** columns and relationships of "company_contact" */
export type Company_Contact = {
  __typename?: 'company_contact';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  primary_phone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "company_contact" */
export type Company_Contact_Aggregate = {
  __typename?: 'company_contact_aggregate';
  aggregate?: Maybe<Company_Contact_Aggregate_Fields>;
  nodes: Array<Company_Contact>;
};

/** aggregate fields of "company_contact" */
export type Company_Contact_Aggregate_Fields = {
  __typename?: 'company_contact_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Company_Contact_Max_Fields>;
  min?: Maybe<Company_Contact_Min_Fields>;
};


/** aggregate fields of "company_contact" */
export type Company_Contact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Contact_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "company_contact". All fields are combined with a logical 'AND'. */
export type Company_Contact_Bool_Exp = {
  _and?: Maybe<Array<Company_Contact_Bool_Exp>>;
  _not?: Maybe<Company_Contact_Bool_Exp>;
  _or?: Maybe<Array<Company_Contact_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  primary_phone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_contact" */
export enum Company_Contact_Constraint {
  /** unique or primary key constraint */
  CompanyContactPkey = 'company_contact_pkey'
}

/** input type for inserting data into table "company_contact" */
export type Company_Contact_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  primary_phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Company_Contact_Max_Fields = {
  __typename?: 'company_contact_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  primary_phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Company_Contact_Min_Fields = {
  __typename?: 'company_contact_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  primary_phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "company_contact" */
export type Company_Contact_Mutation_Response = {
  __typename?: 'company_contact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company_Contact>;
};

/** input type for inserting object relation for remote table "company_contact" */
export type Company_Contact_Obj_Rel_Insert_Input = {
  data: Company_Contact_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Company_Contact_On_Conflict>;
};

/** on conflict condition type for table "company_contact" */
export type Company_Contact_On_Conflict = {
  constraint: Company_Contact_Constraint;
  update_columns?: Array<Company_Contact_Update_Column>;
  where?: Maybe<Company_Contact_Bool_Exp>;
};

/** Ordering options when selecting data from "company_contact". */
export type Company_Contact_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  primary_phone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: company_contact */
export type Company_Contact_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company_contact" */
export enum Company_Contact_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PrimaryPhone = 'primary_phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "company_contact" */
export type Company_Contact_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  primary_phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "company_contact" */
export enum Company_Contact_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PrimaryPhone = 'primary_phone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['uuid']>;
  company_contact?: Maybe<Company_Contact_Obj_Rel_Insert_Input>;
  company_contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  participants?: Maybe<Participant_Arr_Rel_Insert_Input>;
  sessions?: Maybe<Session_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_logins?: Maybe<User_Login_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  company_contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  company_contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Company_On_Conflict>;
};

/** on conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: Maybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  company_contact?: Maybe<Company_Contact_Order_By>;
  company_contact_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  participants_aggregate?: Maybe<Participant_Aggregate_Order_By>;
  sessions_aggregate?: Maybe<Session_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_logins_aggregate?: Maybe<User_Login_Aggregate_Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CompanyContactId = 'company_contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  address_id?: Maybe<Scalars['uuid']>;
  company_contact_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  CompanyContactId = 'company_contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "event" */
export type Event = {
  __typename?: 'event';
  client_timestamp: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  /** An object relationship */
  event_type: Event_Type;
  event_type_id: Scalars['uuid'];
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  /** An object relationship */
  session: Session;
  session_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "event" */
export type EventMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event" */
export type Event_Aggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<Event_Aggregate_Fields>;
  nodes: Array<Event>;
};

/** aggregate fields of "event" */
export type Event_Aggregate_Fields = {
  __typename?: 'event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Max_Fields>;
  min?: Maybe<Event_Min_Fields>;
};


/** aggregate fields of "event" */
export type Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Max_Order_By>;
  min?: Maybe<Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event" */
export type Event_Arr_Rel_Insert_Input = {
  data: Array<Event_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  _and?: Maybe<Array<Event_Bool_Exp>>;
  _not?: Maybe<Event_Bool_Exp>;
  _or?: Maybe<Array<Event_Bool_Exp>>;
  client_timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  event_type?: Maybe<Event_Type_Bool_Exp>;
  event_type_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  session?: Maybe<Session_Bool_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event" */
export enum Event_Constraint {
  /** unique or primary key constraint */
  EventPkey = 'event_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event" */
export type Event_Insert_Input = {
  client_timestamp?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_type?: Maybe<Event_Type_Obj_Rel_Insert_Input>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  session?: Maybe<Session_Obj_Rel_Insert_Input>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Max_Fields = {
  __typename?: 'event_max_fields';
  client_timestamp?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  client_timestamp?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  event_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Min_Fields = {
  __typename?: 'event_min_fields';
  client_timestamp?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  client_timestamp?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  event_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "event" */
export type Event_Mutation_Response = {
  __typename?: 'event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event>;
};

/** on conflict condition type for table "event" */
export type Event_On_Conflict = {
  constraint: Event_Constraint;
  update_columns?: Array<Event_Update_Column>;
  where?: Maybe<Event_Bool_Exp>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  client_timestamp?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  event_type?: Maybe<Event_Type_Order_By>;
  event_type_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  session?: Maybe<Session_Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: event */
export type Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  ClientTimestamp = 'client_timestamp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "event" */
export type Event_Set_Input = {
  client_timestamp?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  event_type_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  metadata?: Maybe<Scalars['jsonb']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "event_type" */
export type Event_Type = {
  __typename?: 'event_type';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "event_type" */
export type Event_Type_Aggregate = {
  __typename?: 'event_type_aggregate';
  aggregate?: Maybe<Event_Type_Aggregate_Fields>;
  nodes: Array<Event_Type>;
};

/** aggregate fields of "event_type" */
export type Event_Type_Aggregate_Fields = {
  __typename?: 'event_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Event_Type_Max_Fields>;
  min?: Maybe<Event_Type_Min_Fields>;
};


/** aggregate fields of "event_type" */
export type Event_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "event_type". All fields are combined with a logical 'AND'. */
export type Event_Type_Bool_Exp = {
  _and?: Maybe<Array<Event_Type_Bool_Exp>>;
  _not?: Maybe<Event_Type_Bool_Exp>;
  _or?: Maybe<Array<Event_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_type" */
export enum Event_Type_Constraint {
  /** unique or primary key constraint */
  EventTypeNameKey = 'event_type_name_key',
  /** unique or primary key constraint */
  EventTypePkey = 'event_type_pkey'
}

/** input type for inserting data into table "event_type" */
export type Event_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Event_Type_Max_Fields = {
  __typename?: 'event_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Event_Type_Min_Fields = {
  __typename?: 'event_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "event_type" */
export type Event_Type_Mutation_Response = {
  __typename?: 'event_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_Type>;
};

/** input type for inserting object relation for remote table "event_type" */
export type Event_Type_Obj_Rel_Insert_Input = {
  data: Event_Type_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Event_Type_On_Conflict>;
};

/** on conflict condition type for table "event_type" */
export type Event_Type_On_Conflict = {
  constraint: Event_Type_Constraint;
  update_columns?: Array<Event_Type_Update_Column>;
  where?: Maybe<Event_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "event_type". */
export type Event_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: event_type */
export type Event_Type_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "event_type" */
export enum Event_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "event_type" */
export type Event_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "event_type" */
export enum Event_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** update columns of table "event" */
export enum Event_Update_Column {
  /** column name */
  ClientTimestamp = 'client_timestamp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EventTypeId = 'event_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "logs" */
export type Logs = {
  __typename?: 'logs';
  action: Logs_Action_Enum;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "logs_action" */
export type Logs_Action = {
  __typename?: 'logs_action';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "logs_action" */
export type Logs_Action_Aggregate = {
  __typename?: 'logs_action_aggregate';
  aggregate?: Maybe<Logs_Action_Aggregate_Fields>;
  nodes: Array<Logs_Action>;
};

/** aggregate fields of "logs_action" */
export type Logs_Action_Aggregate_Fields = {
  __typename?: 'logs_action_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Logs_Action_Max_Fields>;
  min?: Maybe<Logs_Action_Min_Fields>;
};


/** aggregate fields of "logs_action" */
export type Logs_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logs_Action_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "logs_action". All fields are combined with a logical 'AND'. */
export type Logs_Action_Bool_Exp = {
  _and?: Maybe<Array<Logs_Action_Bool_Exp>>;
  _not?: Maybe<Logs_Action_Bool_Exp>;
  _or?: Maybe<Array<Logs_Action_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "logs_action" */
export enum Logs_Action_Constraint {
  /** unique or primary key constraint */
  LogsActionPkey = 'logs_action_pkey'
}

export enum Logs_Action_Enum {
  /** Logs when an user has authenticated successfully in the mobile app or the conductor web app. */
  AuthLogin = 'auth_login',
  /** Logs when an user has intentionally signed out of the mobile app or conductor web app. */
  AuthLogout = 'auth_logout'
}

/** Boolean expression to compare columns of type "logs_action_enum". All fields are combined with logical 'AND'. */
export type Logs_Action_Enum_Comparison_Exp = {
  _eq?: Maybe<Logs_Action_Enum>;
  _in?: Maybe<Array<Logs_Action_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Logs_Action_Enum>;
  _nin?: Maybe<Array<Logs_Action_Enum>>;
};

/** input type for inserting data into table "logs_action" */
export type Logs_Action_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Logs_Action_Max_Fields = {
  __typename?: 'logs_action_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Logs_Action_Min_Fields = {
  __typename?: 'logs_action_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "logs_action" */
export type Logs_Action_Mutation_Response = {
  __typename?: 'logs_action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logs_Action>;
};

/** on conflict condition type for table "logs_action" */
export type Logs_Action_On_Conflict = {
  constraint: Logs_Action_Constraint;
  update_columns?: Array<Logs_Action_Update_Column>;
  where?: Maybe<Logs_Action_Bool_Exp>;
};

/** Ordering options when selecting data from "logs_action". */
export type Logs_Action_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: logs_action */
export type Logs_Action_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "logs_action" */
export enum Logs_Action_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "logs_action" */
export type Logs_Action_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "logs_action" */
export enum Logs_Action_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** aggregated selection of "logs" */
export type Logs_Aggregate = {
  __typename?: 'logs_aggregate';
  aggregate?: Maybe<Logs_Aggregate_Fields>;
  nodes: Array<Logs>;
};

/** aggregate fields of "logs" */
export type Logs_Aggregate_Fields = {
  __typename?: 'logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Logs_Max_Fields>;
  min?: Maybe<Logs_Min_Fields>;
};


/** aggregate fields of "logs" */
export type Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "logs". All fields are combined with a logical 'AND'. */
export type Logs_Bool_Exp = {
  _and?: Maybe<Array<Logs_Bool_Exp>>;
  _not?: Maybe<Logs_Bool_Exp>;
  _or?: Maybe<Array<Logs_Bool_Exp>>;
  action?: Maybe<Logs_Action_Enum_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "logs" */
export enum Logs_Constraint {
  /** unique or primary key constraint */
  LogsPkey = 'logs_pkey'
}

/** input type for inserting data into table "logs" */
export type Logs_Insert_Input = {
  action?: Maybe<Logs_Action_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Logs_Max_Fields = {
  __typename?: 'logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Logs_Min_Fields = {
  __typename?: 'logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "logs" */
export type Logs_Mutation_Response = {
  __typename?: 'logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logs>;
};

/** on conflict condition type for table "logs" */
export type Logs_On_Conflict = {
  constraint: Logs_Constraint;
  update_columns?: Array<Logs_Update_Column>;
  where?: Maybe<Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "logs". */
export type Logs_Order_By = {
  action?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: logs */
export type Logs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "logs" */
export enum Logs_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "logs" */
export type Logs_Set_Input = {
  action?: Maybe<Logs_Action_Enum>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "logs" */
export enum Logs_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "media" */
export type Media = {
  __typename?: 'media';
  content_type?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  storage_key: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "media" */
export type Media_Aggregate = {
  __typename?: 'media_aggregate';
  aggregate?: Maybe<Media_Aggregate_Fields>;
  nodes: Array<Media>;
};

/** aggregate fields of "media" */
export type Media_Aggregate_Fields = {
  __typename?: 'media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Media_Max_Fields>;
  min?: Maybe<Media_Min_Fields>;
};


/** aggregate fields of "media" */
export type Media_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "media". All fields are combined with a logical 'AND'. */
export type Media_Bool_Exp = {
  _and?: Maybe<Array<Media_Bool_Exp>>;
  _not?: Maybe<Media_Bool_Exp>;
  _or?: Maybe<Array<Media_Bool_Exp>>;
  content_type?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  storage_key?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "media" */
export enum Media_Constraint {
  /** unique or primary key constraint */
  MediaPkey = 'media_pkey'
}

/** input type for inserting data into table "media" */
export type Media_Insert_Input = {
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  storage_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Media_Max_Fields = {
  __typename?: 'media_max_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  storage_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Media_Min_Fields = {
  __typename?: 'media_min_fields';
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  storage_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "media" */
export type Media_Mutation_Response = {
  __typename?: 'media_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Media>;
};

/** input type for inserting object relation for remote table "media" */
export type Media_Obj_Rel_Insert_Input = {
  data: Media_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Media_On_Conflict>;
};

/** on conflict condition type for table "media" */
export type Media_On_Conflict = {
  constraint: Media_Constraint;
  update_columns?: Array<Media_Update_Column>;
  where?: Maybe<Media_Bool_Exp>;
};

/** Ordering options when selecting data from "media". */
export type Media_Order_By = {
  content_type?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  storage_key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: media */
export type Media_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "media" */
export enum Media_Select_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StorageKey = 'storage_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "media" */
export type Media_Set_Input = {
  content_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  storage_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "media" */
export enum Media_Update_Column {
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  StorageKey = 'storage_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addParticipants?: Maybe<AddParticipantsOutput>;
  changePreferences?: Maybe<ChangePreferencesOutput>;
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "company_contact" */
  delete_company_contact?: Maybe<Company_Contact_Mutation_Response>;
  /** delete single row from the table: "company_contact" */
  delete_company_contact_by_pk?: Maybe<Company_Contact>;
  /** delete data from the table: "event" */
  delete_event?: Maybe<Event_Mutation_Response>;
  /** delete single row from the table: "event" */
  delete_event_by_pk?: Maybe<Event>;
  /** delete data from the table: "event_type" */
  delete_event_type?: Maybe<Event_Type_Mutation_Response>;
  /** delete single row from the table: "event_type" */
  delete_event_type_by_pk?: Maybe<Event_Type>;
  /** delete data from the table: "logs" */
  delete_logs?: Maybe<Logs_Mutation_Response>;
  /** delete data from the table: "logs_action" */
  delete_logs_action?: Maybe<Logs_Action_Mutation_Response>;
  /** delete single row from the table: "logs_action" */
  delete_logs_action_by_pk?: Maybe<Logs_Action>;
  /** delete single row from the table: "logs" */
  delete_logs_by_pk?: Maybe<Logs>;
  /** delete data from the table: "media" */
  delete_media?: Maybe<Media_Mutation_Response>;
  /** delete single row from the table: "media" */
  delete_media_by_pk?: Maybe<Media>;
  /** delete data from the table: "participant" */
  delete_participant?: Maybe<Participant_Mutation_Response>;
  /** delete single row from the table: "participant" */
  delete_participant_by_pk?: Maybe<Participant>;
  /** delete data from the table: "participant_session" */
  delete_participant_session?: Maybe<Participant_Session_Mutation_Response>;
  /** delete single row from the table: "participant_session" */
  delete_participant_session_by_pk?: Maybe<Participant_Session>;
  /** delete data from the table: "recording_session" */
  delete_recording_session?: Maybe<Recording_Session_Mutation_Response>;
  /** delete single row from the table: "recording_session" */
  delete_recording_session_by_pk?: Maybe<Recording_Session>;
  /** delete data from the table: "role" */
  delete_role?: Maybe<Role_Mutation_Response>;
  /** delete single row from the table: "role" */
  delete_role_by_pk?: Maybe<Role>;
  /** delete data from the table: "scripts" */
  delete_scripts?: Maybe<Scripts_Mutation_Response>;
  /** delete single row from the table: "scripts" */
  delete_scripts_by_pk?: Maybe<Scripts>;
  /** delete data from the table: "session" */
  delete_session?: Maybe<Session_Mutation_Response>;
  /** delete single row from the table: "session" */
  delete_session_by_pk?: Maybe<Session>;
  /** delete data from the table: "speech_phrases" */
  delete_speech_phrases?: Maybe<Speech_Phrases_Mutation_Response>;
  /** delete single row from the table: "speech_phrases" */
  delete_speech_phrases_by_pk?: Maybe<Speech_Phrases>;
  /** delete data from the table: "user_login" */
  delete_user_login?: Maybe<User_Login_Mutation_Response>;
  /** delete single row from the table: "user_login" */
  delete_user_login_by_pk?: Maybe<User_Login>;
  /** delete data from the table: "user_role" */
  delete_user_role?: Maybe<User_Role_Mutation_Response>;
  /** delete single row from the table: "user_role" */
  delete_user_role_by_pk?: Maybe<User_Role>;
  /** delete data from the table: "video_session" */
  delete_video_session?: Maybe<Video_Session_Mutation_Response>;
  /** delete single row from the table: "video_session" */
  delete_video_session_by_pk?: Maybe<Video_Session>;
  finishSession?: Maybe<FinishSessionOutput>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert data into the table: "company_contact" */
  insert_company_contact?: Maybe<Company_Contact_Mutation_Response>;
  /** insert a single row into the table: "company_contact" */
  insert_company_contact_one?: Maybe<Company_Contact>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "event" */
  insert_event?: Maybe<Event_Mutation_Response>;
  /** insert a single row into the table: "event" */
  insert_event_one?: Maybe<Event>;
  /** insert data into the table: "event_type" */
  insert_event_type?: Maybe<Event_Type_Mutation_Response>;
  /** insert a single row into the table: "event_type" */
  insert_event_type_one?: Maybe<Event_Type>;
  /** insert data into the table: "logs" */
  insert_logs?: Maybe<Logs_Mutation_Response>;
  /** insert data into the table: "logs_action" */
  insert_logs_action?: Maybe<Logs_Action_Mutation_Response>;
  /** insert a single row into the table: "logs_action" */
  insert_logs_action_one?: Maybe<Logs_Action>;
  /** insert a single row into the table: "logs" */
  insert_logs_one?: Maybe<Logs>;
  /** insert data into the table: "media" */
  insert_media?: Maybe<Media_Mutation_Response>;
  /** insert a single row into the table: "media" */
  insert_media_one?: Maybe<Media>;
  /** insert data into the table: "participant" */
  insert_participant?: Maybe<Participant_Mutation_Response>;
  /** insert a single row into the table: "participant" */
  insert_participant_one?: Maybe<Participant>;
  /** insert data into the table: "participant_session" */
  insert_participant_session?: Maybe<Participant_Session_Mutation_Response>;
  /** insert a single row into the table: "participant_session" */
  insert_participant_session_one?: Maybe<Participant_Session>;
  /** insert data into the table: "recording_session" */
  insert_recording_session?: Maybe<Recording_Session_Mutation_Response>;
  /** insert a single row into the table: "recording_session" */
  insert_recording_session_one?: Maybe<Recording_Session>;
  /** insert data into the table: "role" */
  insert_role?: Maybe<Role_Mutation_Response>;
  /** insert a single row into the table: "role" */
  insert_role_one?: Maybe<Role>;
  /** insert data into the table: "scripts" */
  insert_scripts?: Maybe<Scripts_Mutation_Response>;
  /** insert a single row into the table: "scripts" */
  insert_scripts_one?: Maybe<Scripts>;
  /** insert data into the table: "session" */
  insert_session?: Maybe<Session_Mutation_Response>;
  /** insert a single row into the table: "session" */
  insert_session_one?: Maybe<Session>;
  /** insert data into the table: "speech_phrases" */
  insert_speech_phrases?: Maybe<Speech_Phrases_Mutation_Response>;
  /** insert a single row into the table: "speech_phrases" */
  insert_speech_phrases_one?: Maybe<Speech_Phrases>;
  /** insert data into the table: "user_login" */
  insert_user_login?: Maybe<User_Login_Mutation_Response>;
  /** insert a single row into the table: "user_login" */
  insert_user_login_one?: Maybe<User_Login>;
  /** insert data into the table: "user_role" */
  insert_user_role?: Maybe<User_Role_Mutation_Response>;
  /** insert a single row into the table: "user_role" */
  insert_user_role_one?: Maybe<User_Role>;
  /** insert data into the table: "video_session" */
  insert_video_session?: Maybe<Video_Session_Mutation_Response>;
  /** insert a single row into the table: "video_session" */
  insert_video_session_one?: Maybe<Video_Session>;
  registerEvent?: Maybe<RegisterEventOutput>;
  sendTalkTimeMessage?: Maybe<TalkTimeMessageEventOutput>;
  startRecording?: Maybe<StartRecordingOutput>;
  syntethizeTextToSpeech?: Maybe<SyntethizeTextToSpeechOutput>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update data of the table: "company_contact" */
  update_company_contact?: Maybe<Company_Contact_Mutation_Response>;
  /** update single row of the table: "company_contact" */
  update_company_contact_by_pk?: Maybe<Company_Contact>;
  /** update data of the table: "event" */
  update_event?: Maybe<Event_Mutation_Response>;
  /** update single row of the table: "event" */
  update_event_by_pk?: Maybe<Event>;
  /** update data of the table: "event_type" */
  update_event_type?: Maybe<Event_Type_Mutation_Response>;
  /** update single row of the table: "event_type" */
  update_event_type_by_pk?: Maybe<Event_Type>;
  /** update data of the table: "logs" */
  update_logs?: Maybe<Logs_Mutation_Response>;
  /** update data of the table: "logs_action" */
  update_logs_action?: Maybe<Logs_Action_Mutation_Response>;
  /** update single row of the table: "logs_action" */
  update_logs_action_by_pk?: Maybe<Logs_Action>;
  /** update single row of the table: "logs" */
  update_logs_by_pk?: Maybe<Logs>;
  /** update data of the table: "media" */
  update_media?: Maybe<Media_Mutation_Response>;
  /** update single row of the table: "media" */
  update_media_by_pk?: Maybe<Media>;
  /** update data of the table: "participant" */
  update_participant?: Maybe<Participant_Mutation_Response>;
  /** update single row of the table: "participant" */
  update_participant_by_pk?: Maybe<Participant>;
  /** update data of the table: "participant_session" */
  update_participant_session?: Maybe<Participant_Session_Mutation_Response>;
  /** update single row of the table: "participant_session" */
  update_participant_session_by_pk?: Maybe<Participant_Session>;
  /** update data of the table: "recording_session" */
  update_recording_session?: Maybe<Recording_Session_Mutation_Response>;
  /** update single row of the table: "recording_session" */
  update_recording_session_by_pk?: Maybe<Recording_Session>;
  /** update data of the table: "role" */
  update_role?: Maybe<Role_Mutation_Response>;
  /** update single row of the table: "role" */
  update_role_by_pk?: Maybe<Role>;
  /** update data of the table: "scripts" */
  update_scripts?: Maybe<Scripts_Mutation_Response>;
  /** update single row of the table: "scripts" */
  update_scripts_by_pk?: Maybe<Scripts>;
  /** update data of the table: "session" */
  update_session?: Maybe<Session_Mutation_Response>;
  /** update single row of the table: "session" */
  update_session_by_pk?: Maybe<Session>;
  /** update data of the table: "speech_phrases" */
  update_speech_phrases?: Maybe<Speech_Phrases_Mutation_Response>;
  /** update single row of the table: "speech_phrases" */
  update_speech_phrases_by_pk?: Maybe<Speech_Phrases>;
  /** update data of the table: "user_login" */
  update_user_login?: Maybe<User_Login_Mutation_Response>;
  /** update single row of the table: "user_login" */
  update_user_login_by_pk?: Maybe<User_Login>;
  /** update data of the table: "user_role" */
  update_user_role?: Maybe<User_Role_Mutation_Response>;
  /** update single row of the table: "user_role" */
  update_user_role_by_pk?: Maybe<User_Role>;
  /** update data of the table: "video_session" */
  update_video_session?: Maybe<Video_Session_Mutation_Response>;
  /** update single row of the table: "video_session" */
  update_video_session_by_pk?: Maybe<Video_Session>;
};


/** mutation root */
export type Mutation_RootAddParticipantsArgs = {
  participants: Array<AddParticipantsItem>;
};


/** mutation root */
export type Mutation_RootChangePreferencesArgs = {
  preferences: Scalars['String'];
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_ContactArgs = {
  where: Company_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventArgs = {
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_TypeArgs = {
  where: Event_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LogsArgs = {
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logs_ActionArgs = {
  where: Logs_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logs_Action_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MediaArgs = {
  where: Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Media_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ParticipantArgs = {
  where: Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Participant_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Participant_SessionArgs = {
  where: Participant_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Participant_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Recording_SessionArgs = {
  where: Recording_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Recording_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RoleArgs = {
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ScriptsArgs = {
  where: Scripts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Scripts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SessionArgs = {
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Speech_PhrasesArgs = {
  where: Speech_Phrases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Speech_Phrases_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_LoginArgs = {
  where: User_Login_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Login_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RoleArgs = {
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Video_SessionArgs = {
  where: Video_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Video_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootFinishSessionArgs = {
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: Maybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_ContactArgs = {
  objects: Array<Company_Contact_Insert_Input>;
  on_conflict?: Maybe<Company_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Contact_OneArgs = {
  object: Company_Contact_Insert_Input;
  on_conflict?: Maybe<Company_Contact_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: Maybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventArgs = {
  objects: Array<Event_Insert_Input>;
  on_conflict?: Maybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_OneArgs = {
  object: Event_Insert_Input;
  on_conflict?: Maybe<Event_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_TypeArgs = {
  objects: Array<Event_Type_Insert_Input>;
  on_conflict?: Maybe<Event_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Type_OneArgs = {
  object: Event_Type_Insert_Input;
  on_conflict?: Maybe<Event_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LogsArgs = {
  objects: Array<Logs_Insert_Input>;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logs_ActionArgs = {
  objects: Array<Logs_Action_Insert_Input>;
  on_conflict?: Maybe<Logs_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logs_Action_OneArgs = {
  object: Logs_Action_Insert_Input;
  on_conflict?: Maybe<Logs_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logs_OneArgs = {
  object: Logs_Insert_Input;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MediaArgs = {
  objects: Array<Media_Insert_Input>;
  on_conflict?: Maybe<Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Media_OneArgs = {
  object: Media_Insert_Input;
  on_conflict?: Maybe<Media_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ParticipantArgs = {
  objects: Array<Participant_Insert_Input>;
  on_conflict?: Maybe<Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participant_OneArgs = {
  object: Participant_Insert_Input;
  on_conflict?: Maybe<Participant_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participant_SessionArgs = {
  objects: Array<Participant_Session_Insert_Input>;
  on_conflict?: Maybe<Participant_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Participant_Session_OneArgs = {
  object: Participant_Session_Insert_Input;
  on_conflict?: Maybe<Participant_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_SessionArgs = {
  objects: Array<Recording_Session_Insert_Input>;
  on_conflict?: Maybe<Recording_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Recording_Session_OneArgs = {
  object: Recording_Session_Insert_Input;
  on_conflict?: Maybe<Recording_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RoleArgs = {
  objects: Array<Role_Insert_Input>;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_OneArgs = {
  object: Role_Insert_Input;
  on_conflict?: Maybe<Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScriptsArgs = {
  objects: Array<Scripts_Insert_Input>;
  on_conflict?: Maybe<Scripts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scripts_OneArgs = {
  object: Scripts_Insert_Input;
  on_conflict?: Maybe<Scripts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SessionArgs = {
  objects: Array<Session_Insert_Input>;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Session_OneArgs = {
  object: Session_Insert_Input;
  on_conflict?: Maybe<Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Speech_PhrasesArgs = {
  objects: Array<Speech_Phrases_Insert_Input>;
  on_conflict?: Maybe<Speech_Phrases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Speech_Phrases_OneArgs = {
  object: Speech_Phrases_Insert_Input;
  on_conflict?: Maybe<Speech_Phrases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_LoginArgs = {
  objects: Array<User_Login_Insert_Input>;
  on_conflict?: Maybe<User_Login_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Login_OneArgs = {
  object: User_Login_Insert_Input;
  on_conflict?: Maybe<User_Login_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RoleArgs = {
  objects: Array<User_Role_Insert_Input>;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Role_OneArgs = {
  object: User_Role_Insert_Input;
  on_conflict?: Maybe<User_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_SessionArgs = {
  objects: Array<Video_Session_Insert_Input>;
  on_conflict?: Maybe<Video_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Session_OneArgs = {
  object: Video_Session_Insert_Input;
  on_conflict?: Maybe<Video_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRegisterEventArgs = {
  clientTimestamp?: Maybe<Scalars['String']>;
  eventTypeName: Scalars['String'];
  metadata: Scalars['String'];
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSendTalkTimeMessageArgs = {
  segmentName: Scalars['String'];
  segmentType: Scalars['String'];
  sessionId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootStartRecordingArgs = {
  sessionId: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSyntethizeTextToSpeechArgs = {
  sessionId: Scalars['String'];
  text: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _set?: Maybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _set?: Maybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ContactArgs = {
  _set?: Maybe<Company_Contact_Set_Input>;
  where: Company_Contact_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Contact_By_PkArgs = {
  _set?: Maybe<Company_Contact_Set_Input>;
  pk_columns: Company_Contact_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventArgs = {
  _append?: Maybe<Event_Append_Input>;
  _delete_at_path?: Maybe<Event_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Delete_Key_Input>;
  _prepend?: Maybe<Event_Prepend_Input>;
  _set?: Maybe<Event_Set_Input>;
  where: Event_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_By_PkArgs = {
  _append?: Maybe<Event_Append_Input>;
  _delete_at_path?: Maybe<Event_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Delete_Key_Input>;
  _prepend?: Maybe<Event_Prepend_Input>;
  _set?: Maybe<Event_Set_Input>;
  pk_columns: Event_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_TypeArgs = {
  _set?: Maybe<Event_Type_Set_Input>;
  where: Event_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Type_By_PkArgs = {
  _set?: Maybe<Event_Type_Set_Input>;
  pk_columns: Event_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LogsArgs = {
  _set?: Maybe<Logs_Set_Input>;
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_ActionArgs = {
  _set?: Maybe<Logs_Action_Set_Input>;
  where: Logs_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_Action_By_PkArgs = {
  _set?: Maybe<Logs_Action_Set_Input>;
  pk_columns: Logs_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_By_PkArgs = {
  _set?: Maybe<Logs_Set_Input>;
  pk_columns: Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MediaArgs = {
  _set?: Maybe<Media_Set_Input>;
  where: Media_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Media_By_PkArgs = {
  _set?: Maybe<Media_Set_Input>;
  pk_columns: Media_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ParticipantArgs = {
  _append?: Maybe<Participant_Append_Input>;
  _delete_at_path?: Maybe<Participant_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Participant_Delete_Elem_Input>;
  _delete_key?: Maybe<Participant_Delete_Key_Input>;
  _inc?: Maybe<Participant_Inc_Input>;
  _prepend?: Maybe<Participant_Prepend_Input>;
  _set?: Maybe<Participant_Set_Input>;
  where: Participant_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Participant_By_PkArgs = {
  _append?: Maybe<Participant_Append_Input>;
  _delete_at_path?: Maybe<Participant_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Participant_Delete_Elem_Input>;
  _delete_key?: Maybe<Participant_Delete_Key_Input>;
  _inc?: Maybe<Participant_Inc_Input>;
  _prepend?: Maybe<Participant_Prepend_Input>;
  _set?: Maybe<Participant_Set_Input>;
  pk_columns: Participant_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Participant_SessionArgs = {
  _set?: Maybe<Participant_Session_Set_Input>;
  where: Participant_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Participant_Session_By_PkArgs = {
  _set?: Maybe<Participant_Session_Set_Input>;
  pk_columns: Participant_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_SessionArgs = {
  _set?: Maybe<Recording_Session_Set_Input>;
  where: Recording_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Recording_Session_By_PkArgs = {
  _set?: Maybe<Recording_Session_Set_Input>;
  pk_columns: Recording_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RoleArgs = {
  _set?: Maybe<Role_Set_Input>;
  where: Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_By_PkArgs = {
  _set?: Maybe<Role_Set_Input>;
  pk_columns: Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ScriptsArgs = {
  _append?: Maybe<Scripts_Append_Input>;
  _delete_at_path?: Maybe<Scripts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Scripts_Delete_Elem_Input>;
  _delete_key?: Maybe<Scripts_Delete_Key_Input>;
  _inc?: Maybe<Scripts_Inc_Input>;
  _prepend?: Maybe<Scripts_Prepend_Input>;
  _set?: Maybe<Scripts_Set_Input>;
  where: Scripts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Scripts_By_PkArgs = {
  _append?: Maybe<Scripts_Append_Input>;
  _delete_at_path?: Maybe<Scripts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Scripts_Delete_Elem_Input>;
  _delete_key?: Maybe<Scripts_Delete_Key_Input>;
  _inc?: Maybe<Scripts_Inc_Input>;
  _prepend?: Maybe<Scripts_Prepend_Input>;
  _set?: Maybe<Scripts_Set_Input>;
  pk_columns: Scripts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SessionArgs = {
  _append?: Maybe<Session_Append_Input>;
  _delete_at_path?: Maybe<Session_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Session_Delete_Elem_Input>;
  _delete_key?: Maybe<Session_Delete_Key_Input>;
  _prepend?: Maybe<Session_Prepend_Input>;
  _set?: Maybe<Session_Set_Input>;
  where: Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Session_By_PkArgs = {
  _append?: Maybe<Session_Append_Input>;
  _delete_at_path?: Maybe<Session_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Session_Delete_Elem_Input>;
  _delete_key?: Maybe<Session_Delete_Key_Input>;
  _prepend?: Maybe<Session_Prepend_Input>;
  _set?: Maybe<Session_Set_Input>;
  pk_columns: Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Speech_PhrasesArgs = {
  _inc?: Maybe<Speech_Phrases_Inc_Input>;
  _set?: Maybe<Speech_Phrases_Set_Input>;
  where: Speech_Phrases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Speech_Phrases_By_PkArgs = {
  _inc?: Maybe<Speech_Phrases_Inc_Input>;
  _set?: Maybe<Speech_Phrases_Set_Input>;
  pk_columns: Speech_Phrases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_LoginArgs = {
  _set?: Maybe<User_Login_Set_Input>;
  where: User_Login_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Login_By_PkArgs = {
  _set?: Maybe<User_Login_Set_Input>;
  pk_columns: User_Login_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_RoleArgs = {
  _set?: Maybe<User_Role_Set_Input>;
  where: User_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Role_By_PkArgs = {
  _set?: Maybe<User_Role_Set_Input>;
  pk_columns: User_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Video_SessionArgs = {
  _set?: Maybe<Video_Session_Set_Input>;
  where: Video_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Video_Session_By_PkArgs = {
  _set?: Maybe<Video_Session_Set_Input>;
  pk_columns: Video_Session_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "participant" */
export type Participant = {
  __typename?: 'participant';
  agora_id: Scalars['bigint'];
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  group: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  metadata: Scalars['jsonb'];
  name_pronunciation: Scalars['String'];
  notification_preference: Scalars['String'];
  /** An array relationship */
  participant_sessions: Array<Participant_Session>;
  /** An aggregate relationship */
  participant_sessions_aggregate: Participant_Session_Aggregate;
  phone: Scalars['String'];
  preferred_name: Scalars['String'];
  profile_photo?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "participant" */
export type ParticipantMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "participant" */
export type ParticipantParticipant_SessionsArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


/** columns and relationships of "participant" */
export type ParticipantParticipant_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};

/** aggregated selection of "participant" */
export type Participant_Aggregate = {
  __typename?: 'participant_aggregate';
  aggregate?: Maybe<Participant_Aggregate_Fields>;
  nodes: Array<Participant>;
};

/** aggregate fields of "participant" */
export type Participant_Aggregate_Fields = {
  __typename?: 'participant_aggregate_fields';
  avg?: Maybe<Participant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Participant_Max_Fields>;
  min?: Maybe<Participant_Min_Fields>;
  stddev?: Maybe<Participant_Stddev_Fields>;
  stddev_pop?: Maybe<Participant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Participant_Stddev_Samp_Fields>;
  sum?: Maybe<Participant_Sum_Fields>;
  var_pop?: Maybe<Participant_Var_Pop_Fields>;
  var_samp?: Maybe<Participant_Var_Samp_Fields>;
  variance?: Maybe<Participant_Variance_Fields>;
};


/** aggregate fields of "participant" */
export type Participant_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Participant_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "participant" */
export type Participant_Aggregate_Order_By = {
  avg?: Maybe<Participant_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Participant_Max_Order_By>;
  min?: Maybe<Participant_Min_Order_By>;
  stddev?: Maybe<Participant_Stddev_Order_By>;
  stddev_pop?: Maybe<Participant_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Participant_Stddev_Samp_Order_By>;
  sum?: Maybe<Participant_Sum_Order_By>;
  var_pop?: Maybe<Participant_Var_Pop_Order_By>;
  var_samp?: Maybe<Participant_Var_Samp_Order_By>;
  variance?: Maybe<Participant_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Participant_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "participant" */
export type Participant_Arr_Rel_Insert_Input = {
  data: Array<Participant_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Participant_On_Conflict>;
};

/** aggregate avg on columns */
export type Participant_Avg_Fields = {
  __typename?: 'participant_avg_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "participant" */
export type Participant_Avg_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "participant". All fields are combined with a logical 'AND'. */
export type Participant_Bool_Exp = {
  _and?: Maybe<Array<Participant_Bool_Exp>>;
  _not?: Maybe<Participant_Bool_Exp>;
  _or?: Maybe<Array<Participant_Bool_Exp>>;
  agora_id?: Maybe<Bigint_Comparison_Exp>;
  company?: Maybe<Company_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  group?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  name_pronunciation?: Maybe<String_Comparison_Exp>;
  notification_preference?: Maybe<String_Comparison_Exp>;
  participant_sessions?: Maybe<Participant_Session_Bool_Exp>;
  phone?: Maybe<String_Comparison_Exp>;
  preferred_name?: Maybe<String_Comparison_Exp>;
  profile_photo?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "participant" */
export enum Participant_Constraint {
  /** unique or primary key constraint */
  ParticipantAgoraIdKey = 'participant_agora_id_key',
  /** unique or primary key constraint */
  ParticipantEmailKey = 'participant_email_key',
  /** unique or primary key constraint */
  ParticipantPkey = 'participant_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Participant_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Participant_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Participant_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "participant" */
export type Participant_Inc_Input = {
  agora_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "participant" */
export type Participant_Insert_Input = {
  agora_id?: Maybe<Scalars['bigint']>;
  company?: Maybe<Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name_pronunciation?: Maybe<Scalars['String']>;
  notification_preference?: Maybe<Scalars['String']>;
  participant_sessions?: Maybe<Participant_Session_Arr_Rel_Insert_Input>;
  phone?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  profile_photo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Participant_Max_Fields = {
  __typename?: 'participant_max_fields';
  agora_id?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  name_pronunciation?: Maybe<Scalars['String']>;
  notification_preference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  profile_photo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "participant" */
export type Participant_Max_Order_By = {
  agora_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  name_pronunciation?: Maybe<Order_By>;
  notification_preference?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  preferred_name?: Maybe<Order_By>;
  profile_photo?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Participant_Min_Fields = {
  __typename?: 'participant_min_fields';
  agora_id?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  name_pronunciation?: Maybe<Scalars['String']>;
  notification_preference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  profile_photo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "participant" */
export type Participant_Min_Order_By = {
  agora_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  name_pronunciation?: Maybe<Order_By>;
  notification_preference?: Maybe<Order_By>;
  phone?: Maybe<Order_By>;
  preferred_name?: Maybe<Order_By>;
  profile_photo?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "participant" */
export type Participant_Mutation_Response = {
  __typename?: 'participant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Participant>;
};

/** input type for inserting object relation for remote table "participant" */
export type Participant_Obj_Rel_Insert_Input = {
  data: Participant_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Participant_On_Conflict>;
};

/** on conflict condition type for table "participant" */
export type Participant_On_Conflict = {
  constraint: Participant_Constraint;
  update_columns?: Array<Participant_Update_Column>;
  where?: Maybe<Participant_Bool_Exp>;
};

/** Ordering options when selecting data from "participant". */
export type Participant_Order_By = {
  agora_id?: Maybe<Order_By>;
  company?: Maybe<Company_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  group?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  name_pronunciation?: Maybe<Order_By>;
  notification_preference?: Maybe<Order_By>;
  participant_sessions_aggregate?: Maybe<Participant_Session_Aggregate_Order_By>;
  phone?: Maybe<Order_By>;
  preferred_name?: Maybe<Order_By>;
  profile_photo?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: participant */
export type Participant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Participant_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "participant" */
export enum Participant_Select_Column {
  /** column name */
  AgoraId = 'agora_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NamePronunciation = 'name_pronunciation',
  /** column name */
  NotificationPreference = 'notification_preference',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredName = 'preferred_name',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "participant_session" */
export type Participant_Session = {
  __typename?: 'participant_session';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  participant?: Maybe<Participant>;
  participant_id: Scalars['uuid'];
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** An aggregate relationship */
  session_aggregate: Session_Aggregate;
  session_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "participant_session" */
export type Participant_SessionSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


/** columns and relationships of "participant_session" */
export type Participant_SessionSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};

/** aggregated selection of "participant_session" */
export type Participant_Session_Aggregate = {
  __typename?: 'participant_session_aggregate';
  aggregate?: Maybe<Participant_Session_Aggregate_Fields>;
  nodes: Array<Participant_Session>;
};

/** aggregate fields of "participant_session" */
export type Participant_Session_Aggregate_Fields = {
  __typename?: 'participant_session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Participant_Session_Max_Fields>;
  min?: Maybe<Participant_Session_Min_Fields>;
};


/** aggregate fields of "participant_session" */
export type Participant_Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Participant_Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "participant_session" */
export type Participant_Session_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Participant_Session_Max_Order_By>;
  min?: Maybe<Participant_Session_Min_Order_By>;
};

/** input type for inserting array relation for remote table "participant_session" */
export type Participant_Session_Arr_Rel_Insert_Input = {
  data: Array<Participant_Session_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Participant_Session_On_Conflict>;
};

/** Boolean expression to filter rows from the table "participant_session". All fields are combined with a logical 'AND'. */
export type Participant_Session_Bool_Exp = {
  _and?: Maybe<Array<Participant_Session_Bool_Exp>>;
  _not?: Maybe<Participant_Session_Bool_Exp>;
  _or?: Maybe<Array<Participant_Session_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  participant?: Maybe<Participant_Bool_Exp>;
  participant_id?: Maybe<Uuid_Comparison_Exp>;
  session?: Maybe<Session_Bool_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "participant_session" */
export enum Participant_Session_Constraint {
  /** unique or primary key constraint */
  ParticipantSessionParticipantIdSessionIdKey = 'participant_session_participant_id_session_id_key',
  /** unique or primary key constraint */
  ParticipantSessionPkey = 'participant_session_pkey'
}

/** input type for inserting data into table "participant_session" */
export type Participant_Session_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  participant?: Maybe<Participant_Obj_Rel_Insert_Input>;
  participant_id?: Maybe<Scalars['uuid']>;
  session?: Maybe<Session_Arr_Rel_Insert_Input>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Participant_Session_Max_Fields = {
  __typename?: 'participant_session_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  participant_id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "participant_session" */
export type Participant_Session_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  participant_id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Participant_Session_Min_Fields = {
  __typename?: 'participant_session_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  participant_id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "participant_session" */
export type Participant_Session_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  participant_id?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "participant_session" */
export type Participant_Session_Mutation_Response = {
  __typename?: 'participant_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Participant_Session>;
};

/** on conflict condition type for table "participant_session" */
export type Participant_Session_On_Conflict = {
  constraint: Participant_Session_Constraint;
  update_columns?: Array<Participant_Session_Update_Column>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "participant_session". */
export type Participant_Session_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  participant?: Maybe<Participant_Order_By>;
  participant_id?: Maybe<Order_By>;
  session_aggregate?: Maybe<Session_Aggregate_Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: participant_session */
export type Participant_Session_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "participant_session" */
export enum Participant_Session_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "participant_session" */
export type Participant_Session_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  participant_id?: Maybe<Scalars['uuid']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "participant_session" */
export enum Participant_Session_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ParticipantId = 'participant_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "participant" */
export type Participant_Set_Input = {
  agora_id?: Maybe<Scalars['bigint']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name_pronunciation?: Maybe<Scalars['String']>;
  notification_preference?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  profile_photo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Participant_Stddev_Fields = {
  __typename?: 'participant_stddev_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "participant" */
export type Participant_Stddev_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Participant_Stddev_Pop_Fields = {
  __typename?: 'participant_stddev_pop_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "participant" */
export type Participant_Stddev_Pop_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Participant_Stddev_Samp_Fields = {
  __typename?: 'participant_stddev_samp_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "participant" */
export type Participant_Stddev_Samp_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Participant_Sum_Fields = {
  __typename?: 'participant_sum_fields';
  agora_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "participant" */
export type Participant_Sum_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** update columns of table "participant" */
export enum Participant_Update_Column {
  /** column name */
  AgoraId = 'agora_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NamePronunciation = 'name_pronunciation',
  /** column name */
  NotificationPreference = 'notification_preference',
  /** column name */
  Phone = 'phone',
  /** column name */
  PreferredName = 'preferred_name',
  /** column name */
  ProfilePhoto = 'profile_photo',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Participant_Var_Pop_Fields = {
  __typename?: 'participant_var_pop_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "participant" */
export type Participant_Var_Pop_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Participant_Var_Samp_Fields = {
  __typename?: 'participant_var_samp_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "participant" */
export type Participant_Var_Samp_Order_By = {
  agora_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Participant_Variance_Fields = {
  __typename?: 'participant_variance_fields';
  agora_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "participant" */
export type Participant_Variance_Order_By = {
  agora_id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_contact" */
  company_contact: Array<Company_Contact>;
  /** fetch aggregated fields from the table: "company_contact" */
  company_contact_aggregate: Company_Contact_Aggregate;
  /** fetch data from the table: "company_contact" using primary key columns */
  company_contact_by_pk?: Maybe<Company_Contact>;
  /** An array relationship */
  event: Array<Event>;
  /** An aggregate relationship */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "event_type" */
  event_type: Array<Event_Type>;
  /** fetch aggregated fields from the table: "event_type" */
  event_type_aggregate: Event_Type_Aggregate;
  /** fetch data from the table: "event_type" using primary key columns */
  event_type_by_pk?: Maybe<Event_Type>;
  getAgoraTempToken?: Maybe<GetAgoraTempTokenOutput>;
  /** fetch data from the table: "logs" */
  logs: Array<Logs>;
  /** fetch data from the table: "logs_action" */
  logs_action: Array<Logs_Action>;
  /** fetch aggregated fields from the table: "logs_action" */
  logs_action_aggregate: Logs_Action_Aggregate;
  /** fetch data from the table: "logs_action" using primary key columns */
  logs_action_by_pk?: Maybe<Logs_Action>;
  /** fetch aggregated fields from the table: "logs" */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table: "media" */
  media: Array<Media>;
  /** fetch aggregated fields from the table: "media" */
  media_aggregate: Media_Aggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table: "participant" */
  participant: Array<Participant>;
  /** fetch aggregated fields from the table: "participant" */
  participant_aggregate: Participant_Aggregate;
  /** fetch data from the table: "participant" using primary key columns */
  participant_by_pk?: Maybe<Participant>;
  /** An array relationship */
  participant_session: Array<Participant_Session>;
  /** An aggregate relationship */
  participant_session_aggregate: Participant_Session_Aggregate;
  /** fetch data from the table: "participant_session" using primary key columns */
  participant_session_by_pk?: Maybe<Participant_Session>;
  /** fetch data from the table: "recording_session" */
  recording_session: Array<Recording_Session>;
  /** fetch aggregated fields from the table: "recording_session" */
  recording_session_aggregate: Recording_Session_Aggregate;
  /** fetch data from the table: "recording_session" using primary key columns */
  recording_session_by_pk?: Maybe<Recording_Session>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "scripts" */
  scripts: Array<Scripts>;
  /** fetch aggregated fields from the table: "scripts" */
  scripts_aggregate: Scripts_Aggregate;
  /** fetch data from the table: "scripts" using primary key columns */
  scripts_by_pk?: Maybe<Scripts>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** An aggregate relationship */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "speech_phrases" */
  speech_phrases: Array<Speech_Phrases>;
  /** fetch aggregated fields from the table: "speech_phrases" */
  speech_phrases_aggregate: Speech_Phrases_Aggregate;
  /** fetch data from the table: "speech_phrases" using primary key columns */
  speech_phrases_by_pk?: Maybe<Speech_Phrases>;
  /** fetch data from the table: "user_login" */
  user_login: Array<User_Login>;
  /** fetch aggregated fields from the table: "user_login" */
  user_login_aggregate: User_Login_Aggregate;
  /** fetch data from the table: "user_login" using primary key columns */
  user_login_by_pk?: Maybe<User_Login>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table: "video_session" */
  video_session: Array<Video_Session>;
  /** fetch aggregated fields from the table: "video_session" */
  video_session_aggregate: Video_Session_Aggregate;
  /** fetch data from the table: "video_session" using primary key columns */
  video_session_by_pk?: Maybe<Video_Session>;
};


export type Query_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCompany_ContactArgs = {
  distinct_on?: Maybe<Array<Company_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Contact_Order_By>>;
  where?: Maybe<Company_Contact_Bool_Exp>;
};


export type Query_RootCompany_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Contact_Order_By>>;
  where?: Maybe<Company_Contact_Bool_Exp>;
};


export type Query_RootCompany_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEventArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Query_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEvent_TypeArgs = {
  distinct_on?: Maybe<Array<Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Type_Order_By>>;
  where?: Maybe<Event_Type_Bool_Exp>;
};


export type Query_RootEvent_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Type_Order_By>>;
  where?: Maybe<Event_Type_Bool_Exp>;
};


export type Query_RootEvent_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetAgoraTempTokenArgs = {
  sessionId: Scalars['String'];
};


export type Query_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_ActionArgs = {
  distinct_on?: Maybe<Array<Logs_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Action_Order_By>>;
  where?: Maybe<Logs_Action_Bool_Exp>;
};


export type Query_RootLogs_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Action_Order_By>>;
  where?: Maybe<Logs_Action_Bool_Exp>;
};


export type Query_RootLogs_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMediaArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Query_RootMedia_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Query_RootMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootParticipantArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


export type Query_RootParticipant_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


export type Query_RootParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootParticipant_SessionArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


export type Query_RootParticipant_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


export type Query_RootParticipant_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRecording_SessionArgs = {
  distinct_on?: Maybe<Array<Recording_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Session_Order_By>>;
  where?: Maybe<Recording_Session_Bool_Exp>;
};


export type Query_RootRecording_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Session_Order_By>>;
  where?: Maybe<Recording_Session_Bool_Exp>;
};


export type Query_RootRecording_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Query_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootScriptsArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};


export type Query_RootScripts_AggregateArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};


export type Query_RootScripts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Query_RootSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSpeech_PhrasesArgs = {
  distinct_on?: Maybe<Array<Speech_Phrases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speech_Phrases_Order_By>>;
  where?: Maybe<Speech_Phrases_Bool_Exp>;
};


export type Query_RootSpeech_Phrases_AggregateArgs = {
  distinct_on?: Maybe<Array<Speech_Phrases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speech_Phrases_Order_By>>;
  where?: Maybe<Speech_Phrases_Bool_Exp>;
};


export type Query_RootSpeech_Phrases_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_LoginArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};


export type Query_RootUser_Login_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};


export type Query_RootUser_Login_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Query_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVideo_SessionArgs = {
  distinct_on?: Maybe<Array<Video_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Session_Order_By>>;
  where?: Maybe<Video_Session_Bool_Exp>;
};


export type Query_RootVideo_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Session_Order_By>>;
  where?: Maybe<Video_Session_Bool_Exp>;
};


export type Query_RootVideo_Session_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "recording_session" */
export type Recording_Session = {
  __typename?: 'recording_session';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  output_directory?: Maybe<Scalars['String']>;
  resource_id: Scalars['String'];
  /** An object relationship */
  session: Session;
  session_id: Scalars['uuid'];
  sid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "recording_session" */
export type Recording_Session_Aggregate = {
  __typename?: 'recording_session_aggregate';
  aggregate?: Maybe<Recording_Session_Aggregate_Fields>;
  nodes: Array<Recording_Session>;
};

/** aggregate fields of "recording_session" */
export type Recording_Session_Aggregate_Fields = {
  __typename?: 'recording_session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Recording_Session_Max_Fields>;
  min?: Maybe<Recording_Session_Min_Fields>;
};


/** aggregate fields of "recording_session" */
export type Recording_Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recording_Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "recording_session". All fields are combined with a logical 'AND'. */
export type Recording_Session_Bool_Exp = {
  _and?: Maybe<Array<Recording_Session_Bool_Exp>>;
  _not?: Maybe<Recording_Session_Bool_Exp>;
  _or?: Maybe<Array<Recording_Session_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  output_directory?: Maybe<String_Comparison_Exp>;
  resource_id?: Maybe<String_Comparison_Exp>;
  session?: Maybe<Session_Bool_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  sid?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "recording_session" */
export enum Recording_Session_Constraint {
  /** unique or primary key constraint */
  RecordingSessionPkey = 'recording_session_pkey',
  /** unique or primary key constraint */
  RecordingSessionSessionIdKey = 'recording_session_session_id_key'
}

/** input type for inserting data into table "recording_session" */
export type Recording_Session_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  output_directory?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  session?: Maybe<Session_Obj_Rel_Insert_Input>;
  session_id?: Maybe<Scalars['uuid']>;
  sid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Recording_Session_Max_Fields = {
  __typename?: 'recording_session_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  output_directory?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  sid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Recording_Session_Min_Fields = {
  __typename?: 'recording_session_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  output_directory?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  sid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "recording_session" */
export type Recording_Session_Mutation_Response = {
  __typename?: 'recording_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Recording_Session>;
};

/** input type for inserting object relation for remote table "recording_session" */
export type Recording_Session_Obj_Rel_Insert_Input = {
  data: Recording_Session_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Recording_Session_On_Conflict>;
};

/** on conflict condition type for table "recording_session" */
export type Recording_Session_On_Conflict = {
  constraint: Recording_Session_Constraint;
  update_columns?: Array<Recording_Session_Update_Column>;
  where?: Maybe<Recording_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "recording_session". */
export type Recording_Session_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  output_directory?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  session?: Maybe<Session_Order_By>;
  session_id?: Maybe<Order_By>;
  sid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: recording_session */
export type Recording_Session_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "recording_session" */
export enum Recording_Session_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutputDirectory = 'output_directory',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Sid = 'sid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "recording_session" */
export type Recording_Session_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  output_directory?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  sid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "recording_session" */
export enum Recording_Session_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutputDirectory = 'output_directory',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Sid = 'sid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "role" */
export type Role = {
  __typename?: 'role';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "role" */
export type Role_Aggregate = {
  __typename?: 'role_aggregate';
  aggregate?: Maybe<Role_Aggregate_Fields>;
  nodes: Array<Role>;
};

/** aggregate fields of "role" */
export type Role_Aggregate_Fields = {
  __typename?: 'role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Max_Fields>;
  min?: Maybe<Role_Min_Fields>;
};


/** aggregate fields of "role" */
export type Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role". All fields are combined with a logical 'AND'. */
export type Role_Bool_Exp = {
  _and?: Maybe<Array<Role_Bool_Exp>>;
  _not?: Maybe<Role_Bool_Exp>;
  _or?: Maybe<Array<Role_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "role" */
export enum Role_Constraint {
  /** unique or primary key constraint */
  RolePkey = 'role_pkey'
}

/** input type for inserting data into table "role" */
export type Role_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Role_Max_Fields = {
  __typename?: 'role_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Role_Min_Fields = {
  __typename?: 'role_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "role" */
export type Role_Mutation_Response = {
  __typename?: 'role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role>;
};

/** on conflict condition type for table "role" */
export type Role_On_Conflict = {
  constraint: Role_Constraint;
  update_columns?: Array<Role_Update_Column>;
  where?: Maybe<Role_Bool_Exp>;
};

/** Ordering options when selecting data from "role". */
export type Role_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: role */
export type Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "role" */
export enum Role_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "role" */
export type Role_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "role" */
export enum Role_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "scripts" */
export type Scripts = {
  __typename?: 'scripts';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  media?: Maybe<Media>;
  media_id?: Maybe<Scalars['uuid']>;
  order: Scalars['Int'];
  preferences?: Maybe<Scalars['jsonb']>;
  segment_name: Scalars['String'];
  segment_type: Scalars['String'];
  /** An object relationship */
  session: Session;
  session_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "scripts" */
export type ScriptsPreferencesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "scripts" */
export type Scripts_Aggregate = {
  __typename?: 'scripts_aggregate';
  aggregate?: Maybe<Scripts_Aggregate_Fields>;
  nodes: Array<Scripts>;
};

/** aggregate fields of "scripts" */
export type Scripts_Aggregate_Fields = {
  __typename?: 'scripts_aggregate_fields';
  avg?: Maybe<Scripts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Scripts_Max_Fields>;
  min?: Maybe<Scripts_Min_Fields>;
  stddev?: Maybe<Scripts_Stddev_Fields>;
  stddev_pop?: Maybe<Scripts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scripts_Stddev_Samp_Fields>;
  sum?: Maybe<Scripts_Sum_Fields>;
  var_pop?: Maybe<Scripts_Var_Pop_Fields>;
  var_samp?: Maybe<Scripts_Var_Samp_Fields>;
  variance?: Maybe<Scripts_Variance_Fields>;
};


/** aggregate fields of "scripts" */
export type Scripts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Scripts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scripts" */
export type Scripts_Aggregate_Order_By = {
  avg?: Maybe<Scripts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Scripts_Max_Order_By>;
  min?: Maybe<Scripts_Min_Order_By>;
  stddev?: Maybe<Scripts_Stddev_Order_By>;
  stddev_pop?: Maybe<Scripts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Scripts_Stddev_Samp_Order_By>;
  sum?: Maybe<Scripts_Sum_Order_By>;
  var_pop?: Maybe<Scripts_Var_Pop_Order_By>;
  var_samp?: Maybe<Scripts_Var_Samp_Order_By>;
  variance?: Maybe<Scripts_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Scripts_Append_Input = {
  preferences?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "scripts" */
export type Scripts_Arr_Rel_Insert_Input = {
  data: Array<Scripts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Scripts_On_Conflict>;
};

/** aggregate avg on columns */
export type Scripts_Avg_Fields = {
  __typename?: 'scripts_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scripts" */
export type Scripts_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scripts". All fields are combined with a logical 'AND'. */
export type Scripts_Bool_Exp = {
  _and?: Maybe<Array<Scripts_Bool_Exp>>;
  _not?: Maybe<Scripts_Bool_Exp>;
  _or?: Maybe<Array<Scripts_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  media?: Maybe<Media_Bool_Exp>;
  media_id?: Maybe<Uuid_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  preferences?: Maybe<Jsonb_Comparison_Exp>;
  segment_name?: Maybe<String_Comparison_Exp>;
  segment_type?: Maybe<String_Comparison_Exp>;
  session?: Maybe<Session_Bool_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "scripts" */
export enum Scripts_Constraint {
  /** unique or primary key constraint */
  ScriptsPkey = 'scripts_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Scripts_Delete_At_Path_Input = {
  preferences?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Scripts_Delete_Elem_Input = {
  preferences?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Scripts_Delete_Key_Input = {
  preferences?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "scripts" */
export type Scripts_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "scripts" */
export type Scripts_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  media?: Maybe<Media_Obj_Rel_Insert_Input>;
  media_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preferences?: Maybe<Scalars['jsonb']>;
  segment_name?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  session?: Maybe<Session_Obj_Rel_Insert_Input>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Scripts_Max_Fields = {
  __typename?: 'scripts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  segment_name?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "scripts" */
export type Scripts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  segment_name?: Maybe<Order_By>;
  segment_type?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Scripts_Min_Fields = {
  __typename?: 'scripts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  segment_name?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "scripts" */
export type Scripts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  media_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  segment_name?: Maybe<Order_By>;
  segment_type?: Maybe<Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "scripts" */
export type Scripts_Mutation_Response = {
  __typename?: 'scripts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Scripts>;
};

/** on conflict condition type for table "scripts" */
export type Scripts_On_Conflict = {
  constraint: Scripts_Constraint;
  update_columns?: Array<Scripts_Update_Column>;
  where?: Maybe<Scripts_Bool_Exp>;
};

/** Ordering options when selecting data from "scripts". */
export type Scripts_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  media?: Maybe<Media_Order_By>;
  media_id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  preferences?: Maybe<Order_By>;
  segment_name?: Maybe<Order_By>;
  segment_type?: Maybe<Order_By>;
  session?: Maybe<Session_Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: scripts */
export type Scripts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Scripts_Prepend_Input = {
  preferences?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "scripts" */
export enum Scripts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  Order = 'order',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  SegmentName = 'segment_name',
  /** column name */
  SegmentType = 'segment_type',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "scripts" */
export type Scripts_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  media_id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preferences?: Maybe<Scalars['jsonb']>;
  segment_name?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Scripts_Stddev_Fields = {
  __typename?: 'scripts_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scripts" */
export type Scripts_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scripts_Stddev_Pop_Fields = {
  __typename?: 'scripts_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scripts" */
export type Scripts_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scripts_Stddev_Samp_Fields = {
  __typename?: 'scripts_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scripts" */
export type Scripts_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Scripts_Sum_Fields = {
  __typename?: 'scripts_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "scripts" */
export type Scripts_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "scripts" */
export enum Scripts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaId = 'media_id',
  /** column name */
  Order = 'order',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  SegmentName = 'segment_name',
  /** column name */
  SegmentType = 'segment_type',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Scripts_Var_Pop_Fields = {
  __typename?: 'scripts_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scripts" */
export type Scripts_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scripts_Var_Samp_Fields = {
  __typename?: 'scripts_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scripts" */
export type Scripts_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Scripts_Variance_Fields = {
  __typename?: 'scripts_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scripts" */
export type Scripts_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

/** columns and relationships of "session" */
export type Session = {
  __typename?: 'session';
  /** An object relationship */
  company: Company;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  date_time: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  event: Array<Event>;
  /** An aggregate relationship */
  event_aggregate: Event_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  participant_session: Array<Participant_Session>;
  /** An aggregate relationship */
  participant_session_aggregate: Participant_Session_Aggregate;
  preferences?: Maybe<Scalars['jsonb']>;
  program: Scalars['String'];
  /** An object relationship */
  recording_session: Recording_Session;
  /** An array relationship */
  script: Array<Scripts>;
  /** An aggregate relationship */
  script_aggregate: Scripts_Aggregate;
  session_name: Scalars['String'];
  timezone: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "session" */
export type SessionEventArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


/** columns and relationships of "session" */
export type SessionEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


/** columns and relationships of "session" */
export type SessionParticipant_SessionArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


/** columns and relationships of "session" */
export type SessionParticipant_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


/** columns and relationships of "session" */
export type SessionPreferencesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "session" */
export type SessionScriptArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};


/** columns and relationships of "session" */
export type SessionScript_AggregateArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};

/** aggregated selection of "session" */
export type Session_Aggregate = {
  __typename?: 'session_aggregate';
  aggregate?: Maybe<Session_Aggregate_Fields>;
  nodes: Array<Session>;
};

/** aggregate fields of "session" */
export type Session_Aggregate_Fields = {
  __typename?: 'session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Session_Max_Fields>;
  min?: Maybe<Session_Min_Fields>;
};


/** aggregate fields of "session" */
export type Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "session" */
export type Session_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Session_Max_Order_By>;
  min?: Maybe<Session_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Session_Append_Input = {
  preferences?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "session" */
export type Session_Arr_Rel_Insert_Input = {
  data: Array<Session_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** Boolean expression to filter rows from the table "session". All fields are combined with a logical 'AND'. */
export type Session_Bool_Exp = {
  _and?: Maybe<Array<Session_Bool_Exp>>;
  _not?: Maybe<Session_Bool_Exp>;
  _or?: Maybe<Array<Session_Bool_Exp>>;
  company?: Maybe<Company_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_time?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  event?: Maybe<Event_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  participant_session?: Maybe<Participant_Session_Bool_Exp>;
  preferences?: Maybe<Jsonb_Comparison_Exp>;
  program?: Maybe<String_Comparison_Exp>;
  recording_session?: Maybe<Recording_Session_Bool_Exp>;
  script?: Maybe<Scripts_Bool_Exp>;
  session_name?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "session" */
export enum Session_Constraint {
  /** unique or primary key constraint */
  SessionPkey = 'session_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Session_Delete_At_Path_Input = {
  preferences?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Session_Delete_Elem_Input = {
  preferences?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Session_Delete_Key_Input = {
  preferences?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "session" */
export type Session_Insert_Input = {
  company?: Maybe<Company_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Event_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  participant_session?: Maybe<Participant_Session_Arr_Rel_Insert_Input>;
  preferences?: Maybe<Scalars['jsonb']>;
  program?: Maybe<Scalars['String']>;
  recording_session?: Maybe<Recording_Session_Obj_Rel_Insert_Input>;
  script?: Maybe<Scripts_Arr_Rel_Insert_Input>;
  session_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Session_Max_Fields = {
  __typename?: 'session_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program?: Maybe<Scalars['String']>;
  session_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "session" */
export type Session_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program?: Maybe<Order_By>;
  session_name?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Session_Min_Fields = {
  __typename?: 'session_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  program?: Maybe<Scalars['String']>;
  session_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "session" */
export type Session_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  program?: Maybe<Order_By>;
  session_name?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "session" */
export type Session_Mutation_Response = {
  __typename?: 'session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Session>;
};

/** input type for inserting object relation for remote table "session" */
export type Session_Obj_Rel_Insert_Input = {
  data: Session_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Session_On_Conflict>;
};

/** on conflict condition type for table "session" */
export type Session_On_Conflict = {
  constraint: Session_Constraint;
  update_columns?: Array<Session_Update_Column>;
  where?: Maybe<Session_Bool_Exp>;
};

/** Ordering options when selecting data from "session". */
export type Session_Order_By = {
  company?: Maybe<Company_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_time?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  event_aggregate?: Maybe<Event_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  participant_session_aggregate?: Maybe<Participant_Session_Aggregate_Order_By>;
  preferences?: Maybe<Order_By>;
  program?: Maybe<Order_By>;
  recording_session?: Maybe<Recording_Session_Order_By>;
  script_aggregate?: Maybe<Scripts_Aggregate_Order_By>;
  session_name?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: session */
export type Session_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Session_Prepend_Input = {
  preferences?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "session" */
export enum Session_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  Program = 'program',
  /** column name */
  SessionName = 'session_name',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "session" */
export type Session_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_time?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  preferences?: Maybe<Scalars['jsonb']>;
  program?: Maybe<Scalars['String']>;
  session_name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "session" */
export enum Session_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateTime = 'date_time',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preferences = 'preferences',
  /** column name */
  Program = 'program',
  /** column name */
  SessionName = 'session_name',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "speech_phrases" */
export type Speech_Phrases = {
  __typename?: 'speech_phrases';
  category: Scalars['String'];
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  preload?: Maybe<Scalars['Boolean']>;
  priority: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "speech_phrases" */
export type Speech_Phrases_Aggregate = {
  __typename?: 'speech_phrases_aggregate';
  aggregate?: Maybe<Speech_Phrases_Aggregate_Fields>;
  nodes: Array<Speech_Phrases>;
};

/** aggregate fields of "speech_phrases" */
export type Speech_Phrases_Aggregate_Fields = {
  __typename?: 'speech_phrases_aggregate_fields';
  avg?: Maybe<Speech_Phrases_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Speech_Phrases_Max_Fields>;
  min?: Maybe<Speech_Phrases_Min_Fields>;
  stddev?: Maybe<Speech_Phrases_Stddev_Fields>;
  stddev_pop?: Maybe<Speech_Phrases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Speech_Phrases_Stddev_Samp_Fields>;
  sum?: Maybe<Speech_Phrases_Sum_Fields>;
  var_pop?: Maybe<Speech_Phrases_Var_Pop_Fields>;
  var_samp?: Maybe<Speech_Phrases_Var_Samp_Fields>;
  variance?: Maybe<Speech_Phrases_Variance_Fields>;
};


/** aggregate fields of "speech_phrases" */
export type Speech_Phrases_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Speech_Phrases_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Speech_Phrases_Avg_Fields = {
  __typename?: 'speech_phrases_avg_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "speech_phrases". All fields are combined with a logical 'AND'. */
export type Speech_Phrases_Bool_Exp = {
  _and?: Maybe<Array<Speech_Phrases_Bool_Exp>>;
  _not?: Maybe<Speech_Phrases_Bool_Exp>;
  _or?: Maybe<Array<Speech_Phrases_Bool_Exp>>;
  category?: Maybe<String_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  preload?: Maybe<Boolean_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "speech_phrases" */
export enum Speech_Phrases_Constraint {
  /** unique or primary key constraint */
  SpeechPhrasesPkey = 'speech_phrases_pkey'
}

/** input type for incrementing numeric columns in table "speech_phrases" */
export type Speech_Phrases_Inc_Input = {
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "speech_phrases" */
export type Speech_Phrases_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  preload?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Speech_Phrases_Max_Fields = {
  __typename?: 'speech_phrases_max_fields';
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Speech_Phrases_Min_Fields = {
  __typename?: 'speech_phrases_min_fields';
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "speech_phrases" */
export type Speech_Phrases_Mutation_Response = {
  __typename?: 'speech_phrases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Speech_Phrases>;
};

/** on conflict condition type for table "speech_phrases" */
export type Speech_Phrases_On_Conflict = {
  constraint: Speech_Phrases_Constraint;
  update_columns?: Array<Speech_Phrases_Update_Column>;
  where?: Maybe<Speech_Phrases_Bool_Exp>;
};

/** Ordering options when selecting data from "speech_phrases". */
export type Speech_Phrases_Order_By = {
  category?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  preload?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: speech_phrases */
export type Speech_Phrases_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "speech_phrases" */
export enum Speech_Phrases_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preload = 'preload',
  /** column name */
  Priority = 'priority',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "speech_phrases" */
export type Speech_Phrases_Set_Input = {
  category?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  preload?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Speech_Phrases_Stddev_Fields = {
  __typename?: 'speech_phrases_stddev_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Speech_Phrases_Stddev_Pop_Fields = {
  __typename?: 'speech_phrases_stddev_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Speech_Phrases_Stddev_Samp_Fields = {
  __typename?: 'speech_phrases_stddev_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Speech_Phrases_Sum_Fields = {
  __typename?: 'speech_phrases_sum_fields';
  priority?: Maybe<Scalars['Int']>;
};

/** update columns of table "speech_phrases" */
export enum Speech_Phrases_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Preload = 'preload',
  /** column name */
  Priority = 'priority',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Speech_Phrases_Var_Pop_Fields = {
  __typename?: 'speech_phrases_var_pop_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Speech_Phrases_Var_Samp_Fields = {
  __typename?: 'speech_phrases_var_samp_fields';
  priority?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Speech_Phrases_Variance_Fields = {
  __typename?: 'speech_phrases_variance_fields';
  priority?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "company_contact" */
  company_contact: Array<Company_Contact>;
  /** fetch aggregated fields from the table: "company_contact" */
  company_contact_aggregate: Company_Contact_Aggregate;
  /** fetch data from the table: "company_contact" using primary key columns */
  company_contact_by_pk?: Maybe<Company_Contact>;
  /** An array relationship */
  event: Array<Event>;
  /** An aggregate relationship */
  event_aggregate: Event_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "event_type" */
  event_type: Array<Event_Type>;
  /** fetch aggregated fields from the table: "event_type" */
  event_type_aggregate: Event_Type_Aggregate;
  /** fetch data from the table: "event_type" using primary key columns */
  event_type_by_pk?: Maybe<Event_Type>;
  /** fetch data from the table: "logs" */
  logs: Array<Logs>;
  /** fetch data from the table: "logs_action" */
  logs_action: Array<Logs_Action>;
  /** fetch aggregated fields from the table: "logs_action" */
  logs_action_aggregate: Logs_Action_Aggregate;
  /** fetch data from the table: "logs_action" using primary key columns */
  logs_action_by_pk?: Maybe<Logs_Action>;
  /** fetch aggregated fields from the table: "logs" */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table: "media" */
  media: Array<Media>;
  /** fetch aggregated fields from the table: "media" */
  media_aggregate: Media_Aggregate;
  /** fetch data from the table: "media" using primary key columns */
  media_by_pk?: Maybe<Media>;
  /** fetch data from the table: "participant" */
  participant: Array<Participant>;
  /** fetch aggregated fields from the table: "participant" */
  participant_aggregate: Participant_Aggregate;
  /** fetch data from the table: "participant" using primary key columns */
  participant_by_pk?: Maybe<Participant>;
  /** An array relationship */
  participant_session: Array<Participant_Session>;
  /** An aggregate relationship */
  participant_session_aggregate: Participant_Session_Aggregate;
  /** fetch data from the table: "participant_session" using primary key columns */
  participant_session_by_pk?: Maybe<Participant_Session>;
  /** fetch data from the table: "recording_session" */
  recording_session: Array<Recording_Session>;
  /** fetch aggregated fields from the table: "recording_session" */
  recording_session_aggregate: Recording_Session_Aggregate;
  /** fetch data from the table: "recording_session" using primary key columns */
  recording_session_by_pk?: Maybe<Recording_Session>;
  /** fetch data from the table: "role" */
  role: Array<Role>;
  /** fetch aggregated fields from the table: "role" */
  role_aggregate: Role_Aggregate;
  /** fetch data from the table: "role" using primary key columns */
  role_by_pk?: Maybe<Role>;
  /** fetch data from the table: "scripts" */
  scripts: Array<Scripts>;
  /** fetch aggregated fields from the table: "scripts" */
  scripts_aggregate: Scripts_Aggregate;
  /** fetch data from the table: "scripts" using primary key columns */
  scripts_by_pk?: Maybe<Scripts>;
  /** fetch data from the table: "session" */
  session: Array<Session>;
  /** An aggregate relationship */
  session_aggregate: Session_Aggregate;
  /** fetch data from the table: "session" using primary key columns */
  session_by_pk?: Maybe<Session>;
  /** fetch data from the table: "speech_phrases" */
  speech_phrases: Array<Speech_Phrases>;
  /** fetch aggregated fields from the table: "speech_phrases" */
  speech_phrases_aggregate: Speech_Phrases_Aggregate;
  /** fetch data from the table: "speech_phrases" using primary key columns */
  speech_phrases_by_pk?: Maybe<Speech_Phrases>;
  /** fetch data from the table: "user_login" */
  user_login: Array<User_Login>;
  /** fetch aggregated fields from the table: "user_login" */
  user_login_aggregate: User_Login_Aggregate;
  /** fetch data from the table: "user_login" using primary key columns */
  user_login_by_pk?: Maybe<User_Login>;
  /** fetch data from the table: "user_role" */
  user_role: Array<User_Role>;
  /** fetch aggregated fields from the table: "user_role" */
  user_role_aggregate: User_Role_Aggregate;
  /** fetch data from the table: "user_role" using primary key columns */
  user_role_by_pk?: Maybe<User_Role>;
  /** fetch data from the table: "video_session" */
  video_session: Array<Video_Session>;
  /** fetch aggregated fields from the table: "video_session" */
  video_session_aggregate: Video_Session_Aggregate;
  /** fetch data from the table: "video_session" using primary key columns */
  video_session_by_pk?: Maybe<Video_Session>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Order_By>>;
  where?: Maybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCompany_ContactArgs = {
  distinct_on?: Maybe<Array<Company_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Contact_Order_By>>;
  where?: Maybe<Company_Contact_Bool_Exp>;
};


export type Subscription_RootCompany_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Contact_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Contact_Order_By>>;
  where?: Maybe<Company_Contact_Bool_Exp>;
};


export type Subscription_RootCompany_Contact_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEventArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvent_TypeArgs = {
  distinct_on?: Maybe<Array<Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Type_Order_By>>;
  where?: Maybe<Event_Type_Bool_Exp>;
};


export type Subscription_RootEvent_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Type_Order_By>>;
  where?: Maybe<Event_Type_Bool_Exp>;
};


export type Subscription_RootEvent_Type_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_ActionArgs = {
  distinct_on?: Maybe<Array<Logs_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Action_Order_By>>;
  where?: Maybe<Logs_Action_Bool_Exp>;
};


export type Subscription_RootLogs_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Action_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Action_Order_By>>;
  where?: Maybe<Logs_Action_Bool_Exp>;
};


export type Subscription_RootLogs_Action_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMediaArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Subscription_RootMedia_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Media_Order_By>>;
  where?: Maybe<Media_Bool_Exp>;
};


export type Subscription_RootMedia_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootParticipantArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


export type Subscription_RootParticipant_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Order_By>>;
  where?: Maybe<Participant_Bool_Exp>;
};


export type Subscription_RootParticipant_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootParticipant_SessionArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


export type Subscription_RootParticipant_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Participant_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Participant_Session_Order_By>>;
  where?: Maybe<Participant_Session_Bool_Exp>;
};


export type Subscription_RootParticipant_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRecording_SessionArgs = {
  distinct_on?: Maybe<Array<Recording_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Session_Order_By>>;
  where?: Maybe<Recording_Session_Bool_Exp>;
};


export type Subscription_RootRecording_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Recording_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Recording_Session_Order_By>>;
  where?: Maybe<Recording_Session_Bool_Exp>;
};


export type Subscription_RootRecording_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoleArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Role_Order_By>>;
  where?: Maybe<Role_Bool_Exp>;
};


export type Subscription_RootRole_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootScriptsArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};


export type Subscription_RootScripts_AggregateArgs = {
  distinct_on?: Maybe<Array<Scripts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scripts_Order_By>>;
  where?: Maybe<Scripts_Bool_Exp>;
};


export type Subscription_RootScripts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSessionArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_AggregateArgs = {
  distinct_on?: Maybe<Array<Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Session_Order_By>>;
  where?: Maybe<Session_Bool_Exp>;
};


export type Subscription_RootSession_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSpeech_PhrasesArgs = {
  distinct_on?: Maybe<Array<Speech_Phrases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speech_Phrases_Order_By>>;
  where?: Maybe<Speech_Phrases_Bool_Exp>;
};


export type Subscription_RootSpeech_Phrases_AggregateArgs = {
  distinct_on?: Maybe<Array<Speech_Phrases_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speech_Phrases_Order_By>>;
  where?: Maybe<Speech_Phrases_Bool_Exp>;
};


export type Subscription_RootSpeech_Phrases_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_LoginArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};


export type Subscription_RootUser_Login_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Login_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Login_Order_By>>;
  where?: Maybe<User_Login_Bool_Exp>;
};


export type Subscription_RootUser_Login_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_RoleArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Role_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Role_Order_By>>;
  where?: Maybe<User_Role_Bool_Exp>;
};


export type Subscription_RootUser_Role_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVideo_SessionArgs = {
  distinct_on?: Maybe<Array<Video_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Session_Order_By>>;
  where?: Maybe<Video_Session_Bool_Exp>;
};


export type Subscription_RootVideo_Session_AggregateArgs = {
  distinct_on?: Maybe<Array<Video_Session_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Video_Session_Order_By>>;
  where?: Maybe<Video_Session_Bool_Exp>;
};


export type Subscription_RootVideo_Session_By_PkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_login" */
export type User_Login = {
  __typename?: 'user_login';
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user_login" */
export type User_Login_Aggregate = {
  __typename?: 'user_login_aggregate';
  aggregate?: Maybe<User_Login_Aggregate_Fields>;
  nodes: Array<User_Login>;
};

/** aggregate fields of "user_login" */
export type User_Login_Aggregate_Fields = {
  __typename?: 'user_login_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Login_Max_Fields>;
  min?: Maybe<User_Login_Min_Fields>;
};


/** aggregate fields of "user_login" */
export type User_Login_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Login_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_login" */
export type User_Login_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Login_Max_Order_By>;
  min?: Maybe<User_Login_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_login" */
export type User_Login_Arr_Rel_Insert_Input = {
  data: Array<User_Login_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<User_Login_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_login". All fields are combined with a logical 'AND'. */
export type User_Login_Bool_Exp = {
  _and?: Maybe<Array<User_Login_Bool_Exp>>;
  _not?: Maybe<User_Login_Bool_Exp>;
  _or?: Maybe<Array<User_Login_Bool_Exp>>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_active?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_login" */
export enum User_Login_Constraint {
  /** unique or primary key constraint */
  UserLoginPkey = 'user_login_pkey'
}

/** input type for inserting data into table "user_login" */
export type User_Login_Insert_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Login_Max_Fields = {
  __typename?: 'user_login_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user_login" */
export type User_Login_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Login_Min_Fields = {
  __typename?: 'user_login_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user_login" */
export type User_Login_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_login" */
export type User_Login_Mutation_Response = {
  __typename?: 'user_login_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Login>;
};

/** on conflict condition type for table "user_login" */
export type User_Login_On_Conflict = {
  constraint: User_Login_Constraint;
  update_columns?: Array<User_Login_Update_Column>;
  where?: Maybe<User_Login_Bool_Exp>;
};

/** Ordering options when selecting data from "user_login". */
export type User_Login_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: user_login */
export type User_Login_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_login" */
export enum User_Login_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "user_login" */
export type User_Login_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "user_login" */
export enum User_Login_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastName = 'last_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "user_role" */
export type User_Role = {
  __typename?: 'user_role';
  id: Scalars['uuid'];
  role_id: Scalars['uuid'];
  user_login_id: Scalars['uuid'];
};

/** aggregated selection of "user_role" */
export type User_Role_Aggregate = {
  __typename?: 'user_role_aggregate';
  aggregate?: Maybe<User_Role_Aggregate_Fields>;
  nodes: Array<User_Role>;
};

/** aggregate fields of "user_role" */
export type User_Role_Aggregate_Fields = {
  __typename?: 'user_role_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Role_Max_Fields>;
  min?: Maybe<User_Role_Min_Fields>;
};


/** aggregate fields of "user_role" */
export type User_Role_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Role_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_role". All fields are combined with a logical 'AND'. */
export type User_Role_Bool_Exp = {
  _and?: Maybe<Array<User_Role_Bool_Exp>>;
  _not?: Maybe<User_Role_Bool_Exp>;
  _or?: Maybe<Array<User_Role_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role_id?: Maybe<Uuid_Comparison_Exp>;
  user_login_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_role" */
export enum User_Role_Constraint {
  /** unique or primary key constraint */
  UserRolePkey = 'user_role_pkey',
  /** unique or primary key constraint */
  UserRoleUserLoginIdRoleIdKey = 'user_role_user_login_id_role_id_key'
}

/** input type for inserting data into table "user_role" */
export type User_Role_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_login_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Role_Max_Fields = {
  __typename?: 'user_role_max_fields';
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_login_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Role_Min_Fields = {
  __typename?: 'user_role_min_fields';
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_login_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_role" */
export type User_Role_Mutation_Response = {
  __typename?: 'user_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Role>;
};

/** on conflict condition type for table "user_role" */
export type User_Role_On_Conflict = {
  constraint: User_Role_Constraint;
  update_columns?: Array<User_Role_Update_Column>;
  where?: Maybe<User_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "user_role". */
export type User_Role_Order_By = {
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  user_login_id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_role */
export type User_Role_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_role" */
export enum User_Role_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserLoginId = 'user_login_id'
}

/** input type for updating data in table "user_role" */
export type User_Role_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_login_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "user_role" */
export enum User_Role_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserLoginId = 'user_login_id'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "video_session" */
export type Video_Session = {
  __typename?: 'video_session';
  created_at: Scalars['timestamp'];
  deleted_at?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  path: Scalars['String'];
  /** An object relationship */
  session?: Maybe<Session>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "video_session" */
export type Video_Session_Aggregate = {
  __typename?: 'video_session_aggregate';
  aggregate?: Maybe<Video_Session_Aggregate_Fields>;
  nodes: Array<Video_Session>;
};

/** aggregate fields of "video_session" */
export type Video_Session_Aggregate_Fields = {
  __typename?: 'video_session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Video_Session_Max_Fields>;
  min?: Maybe<Video_Session_Min_Fields>;
};


/** aggregate fields of "video_session" */
export type Video_Session_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Video_Session_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "video_session". All fields are combined with a logical 'AND'. */
export type Video_Session_Bool_Exp = {
  _and?: Maybe<Array<Video_Session_Bool_Exp>>;
  _not?: Maybe<Video_Session_Bool_Exp>;
  _or?: Maybe<Array<Video_Session_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  deleted_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  path?: Maybe<String_Comparison_Exp>;
  session?: Maybe<Session_Bool_Exp>;
  session_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "video_session" */
export enum Video_Session_Constraint {
  /** unique or primary key constraint */
  VideoSessionPkey = 'video_session_pkey'
}

/** input type for inserting data into table "video_session" */
export type Video_Session_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  session?: Maybe<Session_Obj_Rel_Insert_Input>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Video_Session_Max_Fields = {
  __typename?: 'video_session_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Video_Session_Min_Fields = {
  __typename?: 'video_session_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "video_session" */
export type Video_Session_Mutation_Response = {
  __typename?: 'video_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Video_Session>;
};

/** on conflict condition type for table "video_session" */
export type Video_Session_On_Conflict = {
  constraint: Video_Session_Constraint;
  update_columns?: Array<Video_Session_Update_Column>;
  where?: Maybe<Video_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "video_session". */
export type Video_Session_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  session?: Maybe<Session_Order_By>;
  session_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: video_session */
export type Video_Session_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "video_session" */
export enum Video_Session_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "video_session" */
export type Video_Session_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "video_session" */
export enum Video_Session_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type TempAgoraTokenFragment = { __typename?: 'GetAgoraTempTokenOutput', channelName: string, token: string, participantAgoraId: number };

export type AgoraRecordingFragmentFragment = { __typename?: 'StartRecordingOutput', resourceId: string, sid: string, sessionId: string };

export type GetAgoraTempTokenQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetAgoraTempTokenQuery = { __typename?: 'query_root', getAgoraTempToken?: Maybe<{ __typename?: 'GetAgoraTempTokenOutput', channelName: string, token: string, participantAgoraId: number }> };

export type StartRecordingMutationVariables = Exact<{
  token: Scalars['String'];
  sessionId: Scalars['String'];
}>;


export type StartRecordingMutation = { __typename?: 'mutation_root', startRecording?: Maybe<{ __typename?: 'StartRecordingOutput', resourceId: string, sid: string, sessionId: string }> };

export type CompanyFragment = { __typename?: 'company', id: any, name: string };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'query_root', company: Array<{ __typename?: 'company', id: any, name: string }> };

export type EventsSubscriptionVariables = Exact<{
  sessionId?: Maybe<Scalars['uuid']>;
}>;


export type EventsSubscription = { __typename?: 'subscription_root', event: Array<{ __typename?: 'event', id: any, email: string, metadata: any, created_at: any, event_type: { __typename?: 'event_type', name: string } }> };

export type RegisterEventMutationVariables = Exact<{
  sessionId: Scalars['String'];
  eventTypeName: Scalars['String'];
  metadata: Scalars['String'];
  clientTimestamp: Scalars['String'];
}>;


export type RegisterEventMutation = { __typename?: 'mutation_root', registerEvent?: Maybe<{ __typename?: 'RegisterEventOutput', id: string }> };

export type SendTalkTimeMessageMutationVariables = Exact<{
  sessionId: Scalars['String'];
  segmentName: Scalars['String'];
  segmentType: Scalars['String'];
}>;


export type SendTalkTimeMessageMutation = { __typename?: 'mutation_root', sendTalkTimeMessage?: Maybe<{ __typename?: 'TalkTimeMessageEventOutput', id: string }> };

export type LogAuthSignInMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type LogAuthSignInMutation = { __typename?: 'mutation_root', insert_logs_one?: Maybe<{ __typename?: 'logs', id: any, email: string, action: Logs_Action_Enum }> };

export type LogAuthSignOutMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type LogAuthSignOutMutation = { __typename?: 'mutation_root', insert_logs_one?: Maybe<{ __typename?: 'logs', id: any, email: string, action: Logs_Action_Enum }> };

export type MediaSummaryFragment = { __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string };

export type GetAllMediaQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllMediaQuery = { __typename?: 'query_root', media: Array<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> };

export type InsertMediaOneMutationVariables = Exact<{
  object: Media_Insert_Input;
}>;


export type InsertMediaOneMutation = { __typename?: 'mutation_root', insert_media_one?: Maybe<{ __typename?: 'media', name: string, storage_key: string, content_type?: Maybe<string>, id: any }> };

export type ParticipantSessionFragment = { __typename?: 'participant_session', id: any, participant?: Maybe<{ __typename?: 'participant', email: string, first_name: string, group: string, id: any, last_name: string, metadata: any, name_pronunciation: string, notification_preference: string, phone: string, preferred_name: string, profile_photo?: Maybe<string>, agora_id: any }> };

export type GetParticipantsBySessionIdQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['uuid']>;
}>;


export type GetParticipantsBySessionIdQuery = { __typename?: 'query_root', participant_session: Array<{ __typename?: 'participant_session', id: any, participant?: Maybe<{ __typename?: 'participant', email: string, first_name: string, group: string, id: any, last_name: string, metadata: any, name_pronunciation: string, notification_preference: string, phone: string, preferred_name: string, profile_photo?: Maybe<string>, agora_id: any }> }> };

export type SegmentFragment = { __typename?: 'scripts', id: any, segment_type: string, segment_name: string, order: number, preferences?: Maybe<any>, media_id?: Maybe<any>, media?: Maybe<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> };

export type GetSessionScriptQueryVariables = Exact<{
  sessionId?: Maybe<Scalars['uuid']>;
}>;


export type GetSessionScriptQuery = { __typename?: 'query_root', scripts: Array<{ __typename?: 'scripts', id: any, segment_type: string, segment_name: string, order: number, preferences?: Maybe<any>, media_id?: Maybe<any>, media?: Maybe<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> }> };

export type InsertScriptMutationVariables = Exact<{
  objects: Array<Scripts_Insert_Input> | Scripts_Insert_Input;
}>;


export type InsertScriptMutation = { __typename?: 'mutation_root', insert_scripts?: Maybe<{ __typename?: 'scripts_mutation_response', returning: Array<{ __typename?: 'scripts', id: any, segment_type: string, segment_name: string, order: number, preferences?: Maybe<any>, media_id?: Maybe<any>, media?: Maybe<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> }> }> };

export type UpdateScriptInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
  segment_name: Scalars['String'];
  segment_media?: Maybe<Scalars['uuid']>;
}>;


export type UpdateScriptInfoMutation = { __typename?: 'mutation_root', update_scripts?: Maybe<{ __typename?: 'scripts_mutation_response', returning: Array<{ __typename?: 'scripts', id: any, segment_type: string, segment_name: string, order: number, preferences?: Maybe<any>, media_id?: Maybe<any>, media?: Maybe<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> }> }> };

export type UpdateScriptPreferencesMutationVariables = Exact<{
  id: Scalars['uuid'];
  preferences?: Maybe<Scalars['jsonb']>;
}>;


export type UpdateScriptPreferencesMutation = { __typename?: 'mutation_root', update_scripts?: Maybe<{ __typename?: 'scripts_mutation_response', returning: Array<{ __typename?: 'scripts', id: any, segment_type: string, segment_name: string, order: number, preferences?: Maybe<any>, media_id?: Maybe<any>, media?: Maybe<{ __typename?: 'media', id: any, name: string, content_type?: Maybe<string>, storage_key: string }> }> }> };

export type DeleteSessionScriptMutationVariables = Exact<{
  sessionId: Scalars['uuid'];
}>;


export type DeleteSessionScriptMutation = { __typename?: 'mutation_root', delete_scripts?: Maybe<{ __typename?: 'scripts_mutation_response', affected_rows: number }> };

export type DeleteScriptSegmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteScriptSegmentMutation = { __typename?: 'mutation_root', delete_scripts_by_pk?: Maybe<{ __typename?: 'scripts', segment_name: string }> };

export type SessionFragment = { __typename?: 'session', id: any, session_name: string, program: string, date_time: any, preferences?: Maybe<any>, deleted_at?: Maybe<any>, timezone: string, company: { __typename?: 'company', id: any, name: string } };

export type SessionByIdSubscriptionVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type SessionByIdSubscription = { __typename?: 'subscription_root', session: Array<{ __typename?: 'session', id: any, session_name: string, program: string, date_time: any, preferences?: Maybe<any>, deleted_at?: Maybe<any>, timezone: string, company: { __typename?: 'company', id: any, name: string } }> };

export type GetSessionsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: Maybe<Array<Session_Order_By> | Session_Order_By>;
  where?: Maybe<Session_Bool_Exp>;
}>;


export type GetSessionsQuery = { __typename?: 'query_root', session: Array<{ __typename?: 'session', id: any, session_name: string, program: string, date_time: any, preferences?: Maybe<any>, deleted_at?: Maybe<any>, timezone: string, company: { __typename?: 'company', id: any, name: string } }>, session_aggregate: { __typename?: 'session_aggregate', aggregate?: Maybe<{ __typename?: 'session_aggregate_fields', count: number }> } };

export type GetSessionByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type GetSessionByIdQuery = { __typename?: 'query_root', session: Array<{ __typename?: 'session', id: any, session_name: string, program: string, date_time: any, preferences?: Maybe<any>, deleted_at?: Maybe<any>, timezone: string, company: { __typename?: 'company', id: any, name: string } }> };

export type FinishSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type FinishSessionMutation = { __typename?: 'mutation_root', finishSession?: Maybe<{ __typename?: 'FinishSessionOutput', sessionId: string }> };

export type ChangePreferencesMutationVariables = Exact<{
  sessionId: Scalars['String'];
  preferences: Scalars['String'];
}>;


export type ChangePreferencesMutation = { __typename?: 'mutation_root', changePreferences?: Maybe<{ __typename?: 'ChangePreferencesOutput', id: string }> };

export type SoftDeleteSessionMutationVariables = Exact<{
  sessionId: Scalars['uuid'];
  deletedAtTimestamp: Scalars['timestamptz'];
}>;


export type SoftDeleteSessionMutation = { __typename?: 'mutation_root', update_session?: Maybe<{ __typename?: 'session_mutation_response', returning: Array<{ __typename?: 'session', id: any }> }> };

export type InsertSessionMutationVariables = Exact<{
  session_name: Scalars['String'];
  program: Scalars['String'];
  company_id: Scalars['uuid'];
  date_time: Scalars['timestamptz'];
  timezone: Scalars['String'];
}>;


export type InsertSessionMutation = { __typename?: 'mutation_root', insert_session_one?: Maybe<{ __typename?: 'session', id: any }> };

export type UpdateSessionByIdMutationVariables = Exact<{
  session_id: Scalars['uuid'];
  session_name: Scalars['String'];
  program: Scalars['String'];
  date_time: Scalars['timestamptz'];
  timezone: Scalars['String'];
}>;


export type UpdateSessionByIdMutation = { __typename?: 'mutation_root', update_session_by_pk?: Maybe<{ __typename?: 'session', id: any }> };

export type SpeechPhraseFragment = { __typename?: 'speech_phrases', id: any, category: string, priority: number, preload?: Maybe<boolean>, content: string, created_at: any, updated_at: any, deleted_at?: Maybe<any> };

export type GetSpeechPhrasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSpeechPhrasesQuery = { __typename?: 'query_root', speech_phrases: Array<{ __typename?: 'speech_phrases', id: any, category: string, priority: number, preload?: Maybe<boolean>, content: string, created_at: any, updated_at: any, deleted_at?: Maybe<any> }> };

export type SyntethizeTextToSpeechMutationVariables = Exact<{
  sessionId: Scalars['String'];
  text: Scalars['String'];
}>;


export type SyntethizeTextToSpeechMutation = { __typename?: 'mutation_root', syntethizeTextToSpeech?: Maybe<{ __typename?: 'SyntethizeTextToSpeechOutput', audioContent: string }> };

export const TempAgoraTokenFragmentDoc = gql`
    fragment TempAgoraToken on GetAgoraTempTokenOutput {
  channelName
  token
  participantAgoraId
}
    `;
export const AgoraRecordingFragmentFragmentDoc = gql`
    fragment AgoraRecordingFragment on StartRecordingOutput {
  resourceId
  sid
  sessionId
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on company {
  id
  name
}
    `;
export const ParticipantSessionFragmentDoc = gql`
    fragment ParticipantSession on participant_session {
  id
  participant {
    email
    first_name
    group
    id
    last_name
    metadata
    name_pronunciation
    notification_preference
    phone
    preferred_name
    profile_photo
    agora_id
  }
}
    `;
export const MediaSummaryFragmentDoc = gql`
    fragment MediaSummary on media {
  id
  name
  content_type
  storage_key
}
    `;
export const SegmentFragmentDoc = gql`
    fragment Segment on scripts {
  id
  segment_type
  segment_name
  order
  preferences
  media_id
  media {
    ...MediaSummary
  }
}
    ${MediaSummaryFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment Session on session {
  id
  session_name
  program
  company {
    id
    name
  }
  date_time
  preferences
  deleted_at
  timezone
}
    `;
export const SpeechPhraseFragmentDoc = gql`
    fragment SpeechPhrase on speech_phrases {
  id
  category
  priority
  preload
  content
  created_at
  updated_at
  deleted_at
}
    `;
export const GetAgoraTempTokenDocument = gql`
    query GetAgoraTempToken($sessionId: String!) {
  getAgoraTempToken(sessionId: $sessionId) {
    ...TempAgoraToken
  }
}
    ${TempAgoraTokenFragmentDoc}`;

/**
 * __useGetAgoraTempTokenQuery__
 *
 * To run a query within a React component, call `useGetAgoraTempTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAgoraTempTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAgoraTempTokenQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetAgoraTempTokenQuery(baseOptions: Apollo.QueryHookOptions<GetAgoraTempTokenQuery, GetAgoraTempTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAgoraTempTokenQuery, GetAgoraTempTokenQueryVariables>(GetAgoraTempTokenDocument, options);
      }
export function useGetAgoraTempTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAgoraTempTokenQuery, GetAgoraTempTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAgoraTempTokenQuery, GetAgoraTempTokenQueryVariables>(GetAgoraTempTokenDocument, options);
        }
export type GetAgoraTempTokenQueryHookResult = ReturnType<typeof useGetAgoraTempTokenQuery>;
export type GetAgoraTempTokenLazyQueryHookResult = ReturnType<typeof useGetAgoraTempTokenLazyQuery>;
export type GetAgoraTempTokenQueryResult = Apollo.QueryResult<GetAgoraTempTokenQuery, GetAgoraTempTokenQueryVariables>;
export const StartRecordingDocument = gql`
    mutation StartRecording($token: String!, $sessionId: String!) {
  startRecording(token: $token, sessionId: $sessionId) {
    ...AgoraRecordingFragment
  }
}
    ${AgoraRecordingFragmentFragmentDoc}`;
export type StartRecordingMutationFn = Apollo.MutationFunction<StartRecordingMutation, StartRecordingMutationVariables>;

/**
 * __useStartRecordingMutation__
 *
 * To run a mutation, you first call `useStartRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startRecordingMutation, { data, loading, error }] = useStartRecordingMutation({
 *   variables: {
 *      token: // value for 'token'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useStartRecordingMutation(baseOptions?: Apollo.MutationHookOptions<StartRecordingMutation, StartRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartRecordingMutation, StartRecordingMutationVariables>(StartRecordingDocument, options);
      }
export type StartRecordingMutationHookResult = ReturnType<typeof useStartRecordingMutation>;
export type StartRecordingMutationResult = Apollo.MutationResult<StartRecordingMutation>;
export type StartRecordingMutationOptions = Apollo.BaseMutationOptions<StartRecordingMutation, StartRecordingMutationVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  company(where: {deleted_at: {_is_null: true}}) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const EventsDocument = gql`
    subscription Events($sessionId: uuid) {
  event(where: {session_id: {_eq: $sessionId}}, order_by: {created_at: desc}) {
    id
    email
    event_type {
      name
    }
    metadata
    created_at
  }
}
    `;

/**
 * __useEventsSubscription__
 *
 * To run a query within a React component, call `useEventsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useEventsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<EventsSubscription, EventsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EventsSubscription, EventsSubscriptionVariables>(EventsDocument, options);
      }
export type EventsSubscriptionHookResult = ReturnType<typeof useEventsSubscription>;
export type EventsSubscriptionResult = Apollo.SubscriptionResult<EventsSubscription>;
export const RegisterEventDocument = gql`
    mutation RegisterEvent($sessionId: String!, $eventTypeName: String!, $metadata: String!, $clientTimestamp: String!) {
  registerEvent(
    eventTypeName: $eventTypeName
    metadata: $metadata
    sessionId: $sessionId
    clientTimestamp: $clientTimestamp
  ) {
    id
  }
}
    `;
export type RegisterEventMutationFn = Apollo.MutationFunction<RegisterEventMutation, RegisterEventMutationVariables>;

/**
 * __useRegisterEventMutation__
 *
 * To run a mutation, you first call `useRegisterEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEventMutation, { data, loading, error }] = useRegisterEventMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      eventTypeName: // value for 'eventTypeName'
 *      metadata: // value for 'metadata'
 *      clientTimestamp: // value for 'clientTimestamp'
 *   },
 * });
 */
export function useRegisterEventMutation(baseOptions?: Apollo.MutationHookOptions<RegisterEventMutation, RegisterEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterEventMutation, RegisterEventMutationVariables>(RegisterEventDocument, options);
      }
export type RegisterEventMutationHookResult = ReturnType<typeof useRegisterEventMutation>;
export type RegisterEventMutationResult = Apollo.MutationResult<RegisterEventMutation>;
export type RegisterEventMutationOptions = Apollo.BaseMutationOptions<RegisterEventMutation, RegisterEventMutationVariables>;
export const SendTalkTimeMessageDocument = gql`
    mutation sendTalkTimeMessage($sessionId: String!, $segmentName: String!, $segmentType: String!) {
  sendTalkTimeMessage(
    sessionId: $sessionId
    segmentName: $segmentName
    segmentType: $segmentType
  ) {
    id
  }
}
    `;
export type SendTalkTimeMessageMutationFn = Apollo.MutationFunction<SendTalkTimeMessageMutation, SendTalkTimeMessageMutationVariables>;

/**
 * __useSendTalkTimeMessageMutation__
 *
 * To run a mutation, you first call `useSendTalkTimeMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTalkTimeMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTalkTimeMessageMutation, { data, loading, error }] = useSendTalkTimeMessageMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      segmentName: // value for 'segmentName'
 *      segmentType: // value for 'segmentType'
 *   },
 * });
 */
export function useSendTalkTimeMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendTalkTimeMessageMutation, SendTalkTimeMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTalkTimeMessageMutation, SendTalkTimeMessageMutationVariables>(SendTalkTimeMessageDocument, options);
      }
export type SendTalkTimeMessageMutationHookResult = ReturnType<typeof useSendTalkTimeMessageMutation>;
export type SendTalkTimeMessageMutationResult = Apollo.MutationResult<SendTalkTimeMessageMutation>;
export type SendTalkTimeMessageMutationOptions = Apollo.BaseMutationOptions<SendTalkTimeMessageMutation, SendTalkTimeMessageMutationVariables>;
export const LogAuthSignInDocument = gql`
    mutation LogAuthSignIn($email: String!) {
  insert_logs_one(object: {action: auth_login, email: $email}) {
    id
    email
    action
  }
}
    `;
export type LogAuthSignInMutationFn = Apollo.MutationFunction<LogAuthSignInMutation, LogAuthSignInMutationVariables>;

/**
 * __useLogAuthSignInMutation__
 *
 * To run a mutation, you first call `useLogAuthSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogAuthSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logAuthSignInMutation, { data, loading, error }] = useLogAuthSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLogAuthSignInMutation(baseOptions?: Apollo.MutationHookOptions<LogAuthSignInMutation, LogAuthSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogAuthSignInMutation, LogAuthSignInMutationVariables>(LogAuthSignInDocument, options);
      }
export type LogAuthSignInMutationHookResult = ReturnType<typeof useLogAuthSignInMutation>;
export type LogAuthSignInMutationResult = Apollo.MutationResult<LogAuthSignInMutation>;
export type LogAuthSignInMutationOptions = Apollo.BaseMutationOptions<LogAuthSignInMutation, LogAuthSignInMutationVariables>;
export const LogAuthSignOutDocument = gql`
    mutation LogAuthSignOut($email: String!) {
  insert_logs_one(object: {action: auth_logout, email: $email}) {
    id
    email
    action
  }
}
    `;
export type LogAuthSignOutMutationFn = Apollo.MutationFunction<LogAuthSignOutMutation, LogAuthSignOutMutationVariables>;

/**
 * __useLogAuthSignOutMutation__
 *
 * To run a mutation, you first call `useLogAuthSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogAuthSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logAuthSignOutMutation, { data, loading, error }] = useLogAuthSignOutMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLogAuthSignOutMutation(baseOptions?: Apollo.MutationHookOptions<LogAuthSignOutMutation, LogAuthSignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogAuthSignOutMutation, LogAuthSignOutMutationVariables>(LogAuthSignOutDocument, options);
      }
export type LogAuthSignOutMutationHookResult = ReturnType<typeof useLogAuthSignOutMutation>;
export type LogAuthSignOutMutationResult = Apollo.MutationResult<LogAuthSignOutMutation>;
export type LogAuthSignOutMutationOptions = Apollo.BaseMutationOptions<LogAuthSignOutMutation, LogAuthSignOutMutationVariables>;
export const GetAllMediaDocument = gql`
    query GetAllMedia {
  media(order_by: {created_at: desc}) {
    ...MediaSummary
  }
}
    ${MediaSummaryFragmentDoc}`;

/**
 * __useGetAllMediaQuery__
 *
 * To run a query within a React component, call `useGetAllMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMediaQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllMediaQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMediaQuery, GetAllMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMediaQuery, GetAllMediaQueryVariables>(GetAllMediaDocument, options);
      }
export function useGetAllMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMediaQuery, GetAllMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMediaQuery, GetAllMediaQueryVariables>(GetAllMediaDocument, options);
        }
export type GetAllMediaQueryHookResult = ReturnType<typeof useGetAllMediaQuery>;
export type GetAllMediaLazyQueryHookResult = ReturnType<typeof useGetAllMediaLazyQuery>;
export type GetAllMediaQueryResult = Apollo.QueryResult<GetAllMediaQuery, GetAllMediaQueryVariables>;
export const InsertMediaOneDocument = gql`
    mutation InsertMediaOne($object: media_insert_input!) {
  insert_media_one(object: $object) {
    name
    storage_key
    content_type
    id
  }
}
    `;
export type InsertMediaOneMutationFn = Apollo.MutationFunction<InsertMediaOneMutation, InsertMediaOneMutationVariables>;

/**
 * __useInsertMediaOneMutation__
 *
 * To run a mutation, you first call `useInsertMediaOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMediaOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMediaOneMutation, { data, loading, error }] = useInsertMediaOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertMediaOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertMediaOneMutation, InsertMediaOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMediaOneMutation, InsertMediaOneMutationVariables>(InsertMediaOneDocument, options);
      }
export type InsertMediaOneMutationHookResult = ReturnType<typeof useInsertMediaOneMutation>;
export type InsertMediaOneMutationResult = Apollo.MutationResult<InsertMediaOneMutation>;
export type InsertMediaOneMutationOptions = Apollo.BaseMutationOptions<InsertMediaOneMutation, InsertMediaOneMutationVariables>;
export const GetParticipantsBySessionIdDocument = gql`
    query GetParticipantsBySessionId($sessionId: uuid) {
  participant_session(where: {session_id: {_eq: $sessionId}}) {
    ...ParticipantSession
  }
}
    ${ParticipantSessionFragmentDoc}`;

/**
 * __useGetParticipantsBySessionIdQuery__
 *
 * To run a query within a React component, call `useGetParticipantsBySessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsBySessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsBySessionIdQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetParticipantsBySessionIdQuery(baseOptions?: Apollo.QueryHookOptions<GetParticipantsBySessionIdQuery, GetParticipantsBySessionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantsBySessionIdQuery, GetParticipantsBySessionIdQueryVariables>(GetParticipantsBySessionIdDocument, options);
      }
export function useGetParticipantsBySessionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantsBySessionIdQuery, GetParticipantsBySessionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantsBySessionIdQuery, GetParticipantsBySessionIdQueryVariables>(GetParticipantsBySessionIdDocument, options);
        }
export type GetParticipantsBySessionIdQueryHookResult = ReturnType<typeof useGetParticipantsBySessionIdQuery>;
export type GetParticipantsBySessionIdLazyQueryHookResult = ReturnType<typeof useGetParticipantsBySessionIdLazyQuery>;
export type GetParticipantsBySessionIdQueryResult = Apollo.QueryResult<GetParticipantsBySessionIdQuery, GetParticipantsBySessionIdQueryVariables>;
export const GetSessionScriptDocument = gql`
    query GetSessionScript($sessionId: uuid) {
  scripts(where: {session_id: {_eq: $sessionId}}, order_by: {order: asc}) {
    ...Segment
  }
}
    ${SegmentFragmentDoc}`;

/**
 * __useGetSessionScriptQuery__
 *
 * To run a query within a React component, call `useGetSessionScriptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionScriptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionScriptQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionScriptQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionScriptQuery, GetSessionScriptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionScriptQuery, GetSessionScriptQueryVariables>(GetSessionScriptDocument, options);
      }
export function useGetSessionScriptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionScriptQuery, GetSessionScriptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionScriptQuery, GetSessionScriptQueryVariables>(GetSessionScriptDocument, options);
        }
export type GetSessionScriptQueryHookResult = ReturnType<typeof useGetSessionScriptQuery>;
export type GetSessionScriptLazyQueryHookResult = ReturnType<typeof useGetSessionScriptLazyQuery>;
export type GetSessionScriptQueryResult = Apollo.QueryResult<GetSessionScriptQuery, GetSessionScriptQueryVariables>;
export const InsertScriptDocument = gql`
    mutation InsertScript($objects: [scripts_insert_input!]!) {
  insert_scripts(objects: $objects) {
    returning {
      ...Segment
    }
  }
}
    ${SegmentFragmentDoc}`;
export type InsertScriptMutationFn = Apollo.MutationFunction<InsertScriptMutation, InsertScriptMutationVariables>;

/**
 * __useInsertScriptMutation__
 *
 * To run a mutation, you first call `useInsertScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertScriptMutation, { data, loading, error }] = useInsertScriptMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertScriptMutation(baseOptions?: Apollo.MutationHookOptions<InsertScriptMutation, InsertScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertScriptMutation, InsertScriptMutationVariables>(InsertScriptDocument, options);
      }
export type InsertScriptMutationHookResult = ReturnType<typeof useInsertScriptMutation>;
export type InsertScriptMutationResult = Apollo.MutationResult<InsertScriptMutation>;
export type InsertScriptMutationOptions = Apollo.BaseMutationOptions<InsertScriptMutation, InsertScriptMutationVariables>;
export const UpdateScriptInfoDocument = gql`
    mutation UpdateScriptInfo($id: uuid!, $segment_name: String!, $segment_media: uuid = null) {
  update_scripts(
    where: {id: {_eq: $id}}
    _set: {segment_name: $segment_name, media_id: $segment_media}
  ) {
    returning {
      ...Segment
    }
  }
}
    ${SegmentFragmentDoc}`;
export type UpdateScriptInfoMutationFn = Apollo.MutationFunction<UpdateScriptInfoMutation, UpdateScriptInfoMutationVariables>;

/**
 * __useUpdateScriptInfoMutation__
 *
 * To run a mutation, you first call `useUpdateScriptInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScriptInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScriptInfoMutation, { data, loading, error }] = useUpdateScriptInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      segment_name: // value for 'segment_name'
 *      segment_media: // value for 'segment_media'
 *   },
 * });
 */
export function useUpdateScriptInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScriptInfoMutation, UpdateScriptInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScriptInfoMutation, UpdateScriptInfoMutationVariables>(UpdateScriptInfoDocument, options);
      }
export type UpdateScriptInfoMutationHookResult = ReturnType<typeof useUpdateScriptInfoMutation>;
export type UpdateScriptInfoMutationResult = Apollo.MutationResult<UpdateScriptInfoMutation>;
export type UpdateScriptInfoMutationOptions = Apollo.BaseMutationOptions<UpdateScriptInfoMutation, UpdateScriptInfoMutationVariables>;
export const UpdateScriptPreferencesDocument = gql`
    mutation UpdateScriptPreferences($id: uuid!, $preferences: jsonb) {
  update_scripts(where: {id: {_eq: $id}}, _set: {preferences: $preferences}) {
    returning {
      ...Segment
    }
  }
}
    ${SegmentFragmentDoc}`;
export type UpdateScriptPreferencesMutationFn = Apollo.MutationFunction<UpdateScriptPreferencesMutation, UpdateScriptPreferencesMutationVariables>;

/**
 * __useUpdateScriptPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateScriptPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScriptPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScriptPreferencesMutation, { data, loading, error }] = useUpdateScriptPreferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateScriptPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScriptPreferencesMutation, UpdateScriptPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScriptPreferencesMutation, UpdateScriptPreferencesMutationVariables>(UpdateScriptPreferencesDocument, options);
      }
export type UpdateScriptPreferencesMutationHookResult = ReturnType<typeof useUpdateScriptPreferencesMutation>;
export type UpdateScriptPreferencesMutationResult = Apollo.MutationResult<UpdateScriptPreferencesMutation>;
export type UpdateScriptPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateScriptPreferencesMutation, UpdateScriptPreferencesMutationVariables>;
export const DeleteSessionScriptDocument = gql`
    mutation DeleteSessionScript($sessionId: uuid!) {
  delete_scripts(where: {session_id: {_eq: $sessionId}}) {
    affected_rows
  }
}
    `;
export type DeleteSessionScriptMutationFn = Apollo.MutationFunction<DeleteSessionScriptMutation, DeleteSessionScriptMutationVariables>;

/**
 * __useDeleteSessionScriptMutation__
 *
 * To run a mutation, you first call `useDeleteSessionScriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionScriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionScriptMutation, { data, loading, error }] = useDeleteSessionScriptMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDeleteSessionScriptMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionScriptMutation, DeleteSessionScriptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionScriptMutation, DeleteSessionScriptMutationVariables>(DeleteSessionScriptDocument, options);
      }
export type DeleteSessionScriptMutationHookResult = ReturnType<typeof useDeleteSessionScriptMutation>;
export type DeleteSessionScriptMutationResult = Apollo.MutationResult<DeleteSessionScriptMutation>;
export type DeleteSessionScriptMutationOptions = Apollo.BaseMutationOptions<DeleteSessionScriptMutation, DeleteSessionScriptMutationVariables>;
export const DeleteScriptSegmentDocument = gql`
    mutation DeleteScriptSegment($id: uuid!) {
  delete_scripts_by_pk(id: $id) {
    segment_name
  }
}
    `;
export type DeleteScriptSegmentMutationFn = Apollo.MutationFunction<DeleteScriptSegmentMutation, DeleteScriptSegmentMutationVariables>;

/**
 * __useDeleteScriptSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteScriptSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScriptSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScriptSegmentMutation, { data, loading, error }] = useDeleteScriptSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScriptSegmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteScriptSegmentMutation, DeleteScriptSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteScriptSegmentMutation, DeleteScriptSegmentMutationVariables>(DeleteScriptSegmentDocument, options);
      }
export type DeleteScriptSegmentMutationHookResult = ReturnType<typeof useDeleteScriptSegmentMutation>;
export type DeleteScriptSegmentMutationResult = Apollo.MutationResult<DeleteScriptSegmentMutation>;
export type DeleteScriptSegmentMutationOptions = Apollo.BaseMutationOptions<DeleteScriptSegmentMutation, DeleteScriptSegmentMutationVariables>;
export const SessionByIdDocument = gql`
    subscription SessionById($id: uuid) {
  session(where: {id: {_eq: $id}}) {
    ...Session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useSessionByIdSubscription__
 *
 * To run a query within a React component, call `useSessionByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionByIdSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionByIdSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SessionByIdSubscription, SessionByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SessionByIdSubscription, SessionByIdSubscriptionVariables>(SessionByIdDocument, options);
      }
export type SessionByIdSubscriptionHookResult = ReturnType<typeof useSessionByIdSubscription>;
export type SessionByIdSubscriptionResult = Apollo.SubscriptionResult<SessionByIdSubscription>;
export const GetSessionsDocument = gql`
    query GetSessions($limit: Int!, $offset: Int!, $orderBy: [session_order_by!], $where: session_bool_exp) {
  session(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    ...Session
  }
  session_aggregate(where: {deleted_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useGetSessionsQuery__
 *
 * To run a query within a React component, call `useGetSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSessionsQuery(baseOptions: Apollo.QueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
      }
export function useGetSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
        }
export type GetSessionsQueryHookResult = ReturnType<typeof useGetSessionsQuery>;
export type GetSessionsLazyQueryHookResult = ReturnType<typeof useGetSessionsLazyQuery>;
export type GetSessionsQueryResult = Apollo.QueryResult<GetSessionsQuery, GetSessionsQueryVariables>;
export const GetSessionByIdDocument = gql`
    query GetSessionById($id: uuid) {
  session(where: {id: {_eq: $id}}) {
    ...Session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useGetSessionByIdQuery__
 *
 * To run a query within a React component, call `useGetSessionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSessionByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionByIdQuery, GetSessionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionByIdQuery, GetSessionByIdQueryVariables>(GetSessionByIdDocument, options);
      }
export function useGetSessionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionByIdQuery, GetSessionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionByIdQuery, GetSessionByIdQueryVariables>(GetSessionByIdDocument, options);
        }
export type GetSessionByIdQueryHookResult = ReturnType<typeof useGetSessionByIdQuery>;
export type GetSessionByIdLazyQueryHookResult = ReturnType<typeof useGetSessionByIdLazyQuery>;
export type GetSessionByIdQueryResult = Apollo.QueryResult<GetSessionByIdQuery, GetSessionByIdQueryVariables>;
export const FinishSessionDocument = gql`
    mutation FinishSession($sessionId: String!) {
  finishSession(sessionId: $sessionId) {
    sessionId
  }
}
    `;
export type FinishSessionMutationFn = Apollo.MutationFunction<FinishSessionMutation, FinishSessionMutationVariables>;

/**
 * __useFinishSessionMutation__
 *
 * To run a mutation, you first call `useFinishSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSessionMutation, { data, loading, error }] = useFinishSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useFinishSessionMutation(baseOptions?: Apollo.MutationHookOptions<FinishSessionMutation, FinishSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishSessionMutation, FinishSessionMutationVariables>(FinishSessionDocument, options);
      }
export type FinishSessionMutationHookResult = ReturnType<typeof useFinishSessionMutation>;
export type FinishSessionMutationResult = Apollo.MutationResult<FinishSessionMutation>;
export type FinishSessionMutationOptions = Apollo.BaseMutationOptions<FinishSessionMutation, FinishSessionMutationVariables>;
export const ChangePreferencesDocument = gql`
    mutation ChangePreferences($sessionId: String!, $preferences: String!) {
  changePreferences(sessionId: $sessionId, preferences: $preferences) {
    id
  }
}
    `;
export type ChangePreferencesMutationFn = Apollo.MutationFunction<ChangePreferencesMutation, ChangePreferencesMutationVariables>;

/**
 * __useChangePreferencesMutation__
 *
 * To run a mutation, you first call `useChangePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePreferencesMutation, { data, loading, error }] = useChangePreferencesMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useChangePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<ChangePreferencesMutation, ChangePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePreferencesMutation, ChangePreferencesMutationVariables>(ChangePreferencesDocument, options);
      }
export type ChangePreferencesMutationHookResult = ReturnType<typeof useChangePreferencesMutation>;
export type ChangePreferencesMutationResult = Apollo.MutationResult<ChangePreferencesMutation>;
export type ChangePreferencesMutationOptions = Apollo.BaseMutationOptions<ChangePreferencesMutation, ChangePreferencesMutationVariables>;
export const SoftDeleteSessionDocument = gql`
    mutation SoftDeleteSession($sessionId: uuid!, $deletedAtTimestamp: timestamptz!) {
  update_session(
    where: {id: {_eq: $sessionId}}
    _set: {deleted_at: $deletedAtTimestamp}
  ) {
    returning {
      id
    }
  }
}
    `;
export type SoftDeleteSessionMutationFn = Apollo.MutationFunction<SoftDeleteSessionMutation, SoftDeleteSessionMutationVariables>;

/**
 * __useSoftDeleteSessionMutation__
 *
 * To run a mutation, you first call `useSoftDeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteSessionMutation, { data, loading, error }] = useSoftDeleteSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      deletedAtTimestamp: // value for 'deletedAtTimestamp'
 *   },
 * });
 */
export function useSoftDeleteSessionMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteSessionMutation, SoftDeleteSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteSessionMutation, SoftDeleteSessionMutationVariables>(SoftDeleteSessionDocument, options);
      }
export type SoftDeleteSessionMutationHookResult = ReturnType<typeof useSoftDeleteSessionMutation>;
export type SoftDeleteSessionMutationResult = Apollo.MutationResult<SoftDeleteSessionMutation>;
export type SoftDeleteSessionMutationOptions = Apollo.BaseMutationOptions<SoftDeleteSessionMutation, SoftDeleteSessionMutationVariables>;
export const InsertSessionDocument = gql`
    mutation InsertSession($session_name: String!, $program: String!, $company_id: uuid!, $date_time: timestamptz!, $timezone: String!) {
  insert_session_one(
    object: {session_name: $session_name, program: $program, company_id: $company_id, date_time: $date_time, timezone: $timezone}
  ) {
    id
  }
}
    `;
export type InsertSessionMutationFn = Apollo.MutationFunction<InsertSessionMutation, InsertSessionMutationVariables>;

/**
 * __useInsertSessionMutation__
 *
 * To run a mutation, you first call `useInsertSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSessionMutation, { data, loading, error }] = useInsertSessionMutation({
 *   variables: {
 *      session_name: // value for 'session_name'
 *      program: // value for 'program'
 *      company_id: // value for 'company_id'
 *      date_time: // value for 'date_time'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useInsertSessionMutation(baseOptions?: Apollo.MutationHookOptions<InsertSessionMutation, InsertSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSessionMutation, InsertSessionMutationVariables>(InsertSessionDocument, options);
      }
export type InsertSessionMutationHookResult = ReturnType<typeof useInsertSessionMutation>;
export type InsertSessionMutationResult = Apollo.MutationResult<InsertSessionMutation>;
export type InsertSessionMutationOptions = Apollo.BaseMutationOptions<InsertSessionMutation, InsertSessionMutationVariables>;
export const UpdateSessionByIdDocument = gql`
    mutation UpdateSessionById($session_id: uuid!, $session_name: String!, $program: String!, $date_time: timestamptz!, $timezone: String!) {
  update_session_by_pk(
    pk_columns: {id: $session_id}
    _set: {session_name: $session_name, program: $program, date_time: $date_time, timezone: $timezone}
  ) {
    id
  }
}
    `;
export type UpdateSessionByIdMutationFn = Apollo.MutationFunction<UpdateSessionByIdMutation, UpdateSessionByIdMutationVariables>;

/**
 * __useUpdateSessionByIdMutation__
 *
 * To run a mutation, you first call `useUpdateSessionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionByIdMutation, { data, loading, error }] = useUpdateSessionByIdMutation({
 *   variables: {
 *      session_id: // value for 'session_id'
 *      session_name: // value for 'session_name'
 *      program: // value for 'program'
 *      date_time: // value for 'date_time'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useUpdateSessionByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionByIdMutation, UpdateSessionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionByIdMutation, UpdateSessionByIdMutationVariables>(UpdateSessionByIdDocument, options);
      }
export type UpdateSessionByIdMutationHookResult = ReturnType<typeof useUpdateSessionByIdMutation>;
export type UpdateSessionByIdMutationResult = Apollo.MutationResult<UpdateSessionByIdMutation>;
export type UpdateSessionByIdMutationOptions = Apollo.BaseMutationOptions<UpdateSessionByIdMutation, UpdateSessionByIdMutationVariables>;
export const GetSpeechPhrasesDocument = gql`
    query GetSpeechPhrases {
  speech_phrases(order_by: {category: asc, priority: asc}) {
    ...SpeechPhrase
  }
}
    ${SpeechPhraseFragmentDoc}`;

/**
 * __useGetSpeechPhrasesQuery__
 *
 * To run a query within a React component, call `useGetSpeechPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeechPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeechPhrasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpeechPhrasesQuery(baseOptions?: Apollo.QueryHookOptions<GetSpeechPhrasesQuery, GetSpeechPhrasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpeechPhrasesQuery, GetSpeechPhrasesQueryVariables>(GetSpeechPhrasesDocument, options);
      }
export function useGetSpeechPhrasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpeechPhrasesQuery, GetSpeechPhrasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpeechPhrasesQuery, GetSpeechPhrasesQueryVariables>(GetSpeechPhrasesDocument, options);
        }
export type GetSpeechPhrasesQueryHookResult = ReturnType<typeof useGetSpeechPhrasesQuery>;
export type GetSpeechPhrasesLazyQueryHookResult = ReturnType<typeof useGetSpeechPhrasesLazyQuery>;
export type GetSpeechPhrasesQueryResult = Apollo.QueryResult<GetSpeechPhrasesQuery, GetSpeechPhrasesQueryVariables>;
export const SyntethizeTextToSpeechDocument = gql`
    mutation SyntethizeTextToSpeech($sessionId: String!, $text: String!) {
  syntethizeTextToSpeech(sessionId: $sessionId, text: $text) {
    audioContent
  }
}
    `;
export type SyntethizeTextToSpeechMutationFn = Apollo.MutationFunction<SyntethizeTextToSpeechMutation, SyntethizeTextToSpeechMutationVariables>;

/**
 * __useSyntethizeTextToSpeechMutation__
 *
 * To run a mutation, you first call `useSyntethizeTextToSpeechMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyntethizeTextToSpeechMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syntethizeTextToSpeechMutation, { data, loading, error }] = useSyntethizeTextToSpeechMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSyntethizeTextToSpeechMutation(baseOptions?: Apollo.MutationHookOptions<SyntethizeTextToSpeechMutation, SyntethizeTextToSpeechMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyntethizeTextToSpeechMutation, SyntethizeTextToSpeechMutationVariables>(SyntethizeTextToSpeechDocument, options);
      }
export type SyntethizeTextToSpeechMutationHookResult = ReturnType<typeof useSyntethizeTextToSpeechMutation>;
export type SyntethizeTextToSpeechMutationResult = Apollo.MutationResult<SyntethizeTextToSpeechMutation>;
export type SyntethizeTextToSpeechMutationOptions = Apollo.BaseMutationOptions<SyntethizeTextToSpeechMutation, SyntethizeTextToSpeechMutationVariables>;